import { useState, useEffect, useRef } from "react";
import React from "react";
import FeedIcon from "@mui/icons-material/Feed";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, message, Modal } from "antd";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CBS2 from "../../assets/img/CBS2.jpeg";
import CBS3 from "../../assets/img/CBS3.jpeg";
import CBS4 from "../../assets/img/CBS4.jpeg";
import CBS1 from "../../assets/img/CBS1.png";
import CBS5 from "../../assets/img/CBS5.png";
import { Select } from "antd";
import pdfToText from "react-pdftotext";
import ArchiveIcon from "@mui/icons-material/Archive";
import { UserOutlined, QuestionOutlined } from "@ant-design/icons";

const { Option } = Select;

const ModalApplication = ({
  loanType,
  loanCategory,
  requestingLoanAmount,
  expectingInterestRate,
  loanPeriod,
  request_principal,
  request_monthly_installment,
  total_admin_fee_by_month,
  total_admin_fee,
  request_revenue,
  adminFee,
  promoCode,
  keymanChoices,
}) => {
  const [data, setData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [residentialStatus, setResidentalStatus] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [registeredAddress, setRegisteredAddress] = useState("");
  const [registeredBusinessName, setRegisteredBusinessName] = useState("");
  const [countryOfIncorporation, setCountryOfIncorporation] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [UEN, setUEN] = useState("");
  const [registeredBusinessAddress, setRegisteredBusinessAddress] =
    useState("");
  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [shareHolderContactNo, setDirectorShareholderPhoneNo] = useState("");
  const [shareHolderName, setDirectorShareholderName] = useState("");
  const [shareHolderEmail, setDirectorShareholderEmail] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [fileList3, setFileList3] = useState([]);
  const [fileList4, setFileList4] = useState([]);
  const [fileList5, setFileList5] = useState([]);
  const [fileList6, setFileList6] = useState([]);
  const [fileList7, setFileList7] = useState([]);
  const [fileList8, setFileList8] = useState([]);
  const [fileList9, setFileList9] = useState([]);
  const [creditReportFile, setCreditReportFile] = useState([]);
  const [creditFile, setCreditFile] = useState(null);
  const history = useHistory();
  const [redirectTo, setRedirectTo] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [noFinancialReport, setNoFinancialReport] = useState(false);
  const [userData, setUserData] = useState(null);
  const [savePdfReminder, setSavePdfReminder] = useState(false);
  const [corporateStructure, setCorporateStructure] = useState(false);
  const [corporateNotificationSubmit, setCoporateNotificationSubmit] =
    useState(false);
  const [userId, setUserId] = useState("");
  const [otherLoanRequirementMet, setOtherLoanRequirementMet] = useState(false)
  const [sendToOtherFinTechModal, setSendToOtherFinTechModal] = useState(false)

  useEffect(()=>{
    if(userDetails) {
      
      const creditReportData = {
        EnquiryDate:
          creditFile && creditFile !== "" && enquiryDate
            ? enquiryDate
            : null,
        SecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Secured Credit Limit",
                  "Unsecured Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        UnsecuredCreditLimit:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Unsecured Credit Limit",
                  "Exempted Credit Limit"
                ).replace(/,/g, "")
              )
            : null,
        CreditScore:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "financial institution.  Score",
                  "Risk Grade"
                )
              )
            : null,
        RiskGrade:
          creditFile && creditFile !== ""
            ? extractTextBetween("Risk Grade ", "Risk Grade Description")
            : null,
        ProbabilityofDefault:
          creditFile && creditFile !== ""
            ? parseFloat(
                extractTextBetween(
                  "Probability of Default ",
                  " For more details on the description"
                ).replace(/,/g, "")
              )
            : null,
        SecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
            : null,
        UnsecuredCreditUtilized:
          creditFile && creditFile !== ""
            ? extractTextBetween(
                "Total  ",
                " Aggregated Outstanding Balances for Preceding 5 Months"
              )
                ?.split("  ")
                ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
            : null,
        SecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                  parseFloat(
                    extractTextBetween(
                      "Secured Credit Limit",
                      "Unsecured Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        UnsecuredCreditUtilizationinpercentage:
          creditFile && creditFile !== ""
            ? (
                (extractTextBetween(
                  "Total  ",
                  " Aggregated Outstanding Balances for Preceding 5 Months"
                )
                  ?.split("  ")
                  ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                  parseFloat(
                    extractTextBetween(
                      "Unsecured Credit Limit",
                      "Exempted Credit Limit"
                    )?.replace(/,/g, "")
                  )) *
                100
              )?.toFixed(2)
            : null,
        MonthlyCommitment:
          creditFile && creditFile !== "" && monthlyCommitment
            ? monthlyCommitment.toLocaleString("en-US", {
                style: "currency",
                currency: "SGD",
              })
            : null,
        NoteworthyOngoingLoans:
          CBSImpLoan && CBSImpLoan.length !== 0 ? CBSImpLoan : null,
        Last6MonthsGraphChartMaxData:
          chartMaxValue && chartData ? chartMaxValue : null,
        Last6MonthsGraphChartData:
          chartMaxValue && chartData ? chartData : null,
        CBSImpLoanSupplementaryInstallment:
          cBSImpLoanSupplementaryMonthlyInstallment
            ? cBSImpLoanSupplementaryMonthlyInstallment
            : null,
      };
      // console.log(userDetails, creditReportData)
    }
  },[userDetails])

  const UniversaleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // console.log(file);
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/file/uploadUniversalFile",
        {
          method: "POST",
          body: formData,
        }
      );
      if (response1.status === 200) {
        const data = await response1.json();
        const blobUrl = data.url; // Assuming this is the URL you want to store
        // console.log(blobUrl);
        return blobUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserId(json.User.user_id);
        const singpassData = JSON.parse(json.User.singpass_data);
        if (
          singpassData?.entity?.shareholders?.["shareholders-list"]?.some(
            (item) => item.hasOwnProperty("entity-reference")
          )
        ) {
          setCorporateStructure(true);
        } else {
          console.log("no entity shareholder");
        }
        setUserData(json.User);
        setUserDetails(singpassData);
        setFirstName(singpassData?.person?.name?.value?.split(" ")?.[0]);
        setLastName(singpassData?.person?.name?.value?.split(" ")?.[1]);
        setRegisteredBusinessName(
          singpassData?.entity?.["basic-profile"]?.["entity-name"]?.value !==
            undefined
            ? singpassData?.entity?.["basic-profile"]?.["entity-name"]?.value
            : ""
        );
        setCountryOfIncorporation(
          singpassData?.entity?.["basic-profile"]?.["country-of-incorporation"]
            ?.desc !== undefined
            ? singpassData?.entity?.["basic-profile"]?.[
                "country-of-incorporation"
              ]?.desc
            : ""
        );
        setRegistrationDate(
          singpassData?.entity?.["basic-profile"]?.["registration-date"]
            ?.value !== undefined
            ? singpassData?.entity?.["basic-profile"]?.["registration-date"]
                ?.value
            : ""
        );
        setUEN(
          singpassData?.entity?.["basic-profile"]?.["uen"]?.value !== undefined
            ? singpassData?.entity?.["basic-profile"]?.["uen"]?.value
            : ""
        );
        setResidentalStatus(
          singpassData?.person?.residentialstatus?.desc !== undefined
            ? singpassData?.person?.residentialstatus?.desc
            : ""
        );
        setNationality(
          singpassData?.person?.nationality?.desc !== undefined
            ? singpassData?.person?.nationality?.desc
            : ""
        );
        const formattedAddress =
          (singpassData?.person?.regadd?.unit?.value !== undefined
            ? `Unit ${singpassData?.person?.regadd?.unit?.value}, `
            : "") +
          (singpassData?.person?.regadd?.floor?.value !== undefined
            ? `Floor ${singpassData?.person?.regadd?.floor?.value}, `
            : "") +
          (singpassData?.person?.regadd?.block?.value !== undefined
            ? `Block ${singpassData?.person?.regadd?.block?.value}, `
            : "") +
          (singpassData?.person?.regadd?.street?.value !== undefined
            ? `${singpassData?.person?.regadd?.street?.value}, `
            : "") +
          (singpassData?.person?.regadd?.postal?.value !== undefined
            ? `${singpassData?.person?.regadd?.postal?.value}, `
            : "") +
          (singpassData?.person?.regadd?.country?.desc !== undefined
            ? `${singpassData?.person?.regadd?.country?.desc}.`
            : "");
        setRegisteredAddress(formattedAddress);
        const formattedAddressEntity =
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit
            ?.value !== undefined
            ? `Unit ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.unit?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor
            ?.value !== undefined
            ? `Floor ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.floor?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block
            ?.value !== undefined
            ? `Block ${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.block?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street
            ?.value !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.street?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal
            ?.value !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.postal?.value}, `
            : "") +
          (singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country
            ?.desc !== undefined
            ? `${singpassData?.entity?.addresses?.["addresses-list"]?.[0]?.country?.desc}.`
            : "");
        setRegisteredBusinessAddress(formattedAddressEntity);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function extractTextBetween(beforeText, afterText) {
    if (creditFile !== null) {
      // Replace multiple spaces in beforeText and afterText with \s+ to match any number of spaces
      const beforeTextPattern = beforeText.replace(/\s+/g, "\\s+");
      const afterTextPattern = afterText.replace(/\s+/g, "\\s+");

      // Create a regex to find text between the beforeTextPattern and afterTextPattern
      const regex = new RegExp(`${beforeTextPattern}(.*?)${afterTextPattern}`);

      const match = creditFile.match(regex);
      if (match && match[1]) {
        return match[1].trim(); // Extract and trim any leading/trailing spaces
      } else {
        return "Text not found between specified markers";
      }
    }
  }

  function extractTextBetweeforloandetails(creditText, beforeText, afterText) {
    // Find the starting index of beforeText in the original text
    if (creditText !== null) {
      const startIndex = creditText.indexOf(beforeText);
      if (startIndex === -1) {
        return "Before text not found";
      }

      // Adjust the starting index to exclude the beforeText
      const adjustedStartIndex = startIndex + beforeText.length;

      // Find the ending index of afterText in the original text
      const endIndex = creditText.indexOf(afterText, adjustedStartIndex);
      if (endIndex === -1) {
        return "After text not found";
      }

      // Extract the text between beforeText and afterText
      const extractedText = creditText.substring(adjustedStartIndex, endIndex);

      return extractedText;
    }
  }

  function removeCharactersAroundPage(inputString) {
    // Regular expression to find the word "page" and capture the preceding and following characters
    const regex = /(.{0,19})page(.{0,7})/g; // Adjusted to capture more characters before "page"

    // Replace the matched segments with the word "page" alone
    const result = inputString.replace(regex, (match, p1, p2) => {
      // Determine the number of characters to remove from the beginning and the end
      const charsToRemoveBefore = 19 - 11; // 4 characters before the original 11
      const charsToRemoveAfter = 11 - 4;

      const removedStart =
        p1.length > charsToRemoveBefore
          ? p1.slice(0, -charsToRemoveBefore)
          : "";
      const removedEnd =
        p2.length > charsToRemoveAfter ? p2.slice(charsToRemoveAfter) : "";
      return `${removedStart}page${removedEnd}`;
    });

    return result;
  }

  function splitStringToRows(inputString) {
    // Use a regular expression to match exactly two spaces
    const rows = inputString.split(/(?<! ) {2}(?! )/);

    return rows;
  }

  function cleanUpArray(inputArray) {
    const cleanedArray = [];

    for (let i = 0; i < inputArray.length; i++) {
      if (inputArray[i].includes("private residential purchase")) {
        // If the current item includes "private residential purchase", combine it with the next item
        const combinedLine = inputArray[i] + " " + (inputArray[i + 1] || "");
        cleanedArray.push(combinedLine.trim());
        // Skip the next item since it's already combined
        i++;
      } else {
        // Otherwise, just push the current item to the cleaned array
        cleanedArray.push(inputArray[i]);
      }
    }

    return cleanedArray;
  }

  const [CBSImpLoan, setCBSImpLoan] = useState(null);
  const [monthlyCommitment, setMonthlyCommitment] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [enquiryDate, setEnquiryDate] = useState(null);
  const [chartMaxValue, setChartMaxValue] = useState(null);
  const [
    cBSImpLoanSupplementaryMonthlyInstallment,
    setCBSImpLoanSupplementaryMonthlyInstallment,
  ] = useState(null);

  function convertDate(dateString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Split the input date string
    const [day, month, year] = dateString.split("/");

    // Convert month number to month name
    const monthName = months[parseInt(month) - 1];

    // Return the formatted date string
    return `${day} ${monthName} ${year}`;
  }

  useEffect(() => {
    if (creditFile) {
      const textWithoutPage = removeCharactersAroundPage(
        creditFile.toLowerCase()
      ).replace(/page/g, "");

      const extract = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated outstanding balances  month   product type   grantor bank   secured balances  unsecured balances interest bearing  unsecured balances non-interest bearing  exempted unsecured balances  ",
        "total"
      );

      const extract2ForMonthlyCommitment = extractTextBetweeforloandetails(
        textWithoutPage,
        "aggregated monthly instalment",
        "aggregated monthly instalments for preceding 5 months"
      );

      console.log(extract2ForMonthlyCommitment)

      const extract3ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          creditFile,
          "Aggregated Outstanding Balances for Preceding 5 Months",
          "Aggregated Monthly Instalment"
        );

      const extract4ForChartingOutstandingBalance =
        extractTextBetweeforloandetails(
          textWithoutPage,
          "aggregated monthly instalment  month   product type   grantor bank   account type**   property***   non-property   unsecured  exempted unsecured  ",
          "aggregated monthly instalments for preceding 5 months"
        );

      const extractEnquiryDate = extractTextBetweeforloandetails(
        textWithoutPage,
        "enquiry date",
        "reference"
      );

      if (extractEnquiryDate !== "Before text not found") {
        setEnquiryDate(extractEnquiryDate)?.trim();
      }
      function parseData(inputString) {
        // Adjusted regex to account for extra numbers before the relevant value
        const regex = /([a-zA-Z]+\s\d{4})\s+All\s+All\s+[\d,.]+\s+([\d,.]+\.\d{2})/g;

        const result = [];
        let match;
      
        // Loop through the matches and create the array of objects
        while ((match = regex.exec(inputString)) !== null) {
          const date = match[1];
          const value = match[2];
          result.push({ date, value });
        }
      
        return result;
      }
      
      
      function getMaxValue(data) {
        return data.reduce((max, current) => {
          // Parse the current value to a float for accurate comparison
          const currentValue = parseFloat(current.value.replace(/,/g, ""));
          return currentValue > max ? currentValue : max;
        }, 0);
      }
      function getNextMonth(data) {
        const monthNames = [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ];

        // Extract the first date from the data
        const firstDate = data?.[0]?.date;
        if (firstDate !== undefined) {
          const [firstMonth, firstYear] = firstDate.split(" ");
          const monthIndex = monthNames.indexOf(firstMonth.toLowerCase());
          const year = parseInt(firstYear, 10);

          // Calculate the next month and year
          const nextMonthIndex = (monthIndex + 1) % 12;
          const nextYear = monthIndex === 11 ? year + 1 : year;
          const nextMonth = monthNames[nextMonthIndex];

          return `${nextMonth} ${nextYear}`;
        }
      }
      let chartDate = parseData(extract3ForChartingOutstandingBalance);
      // console.log(chartDate);
      if (chartDate && chartDate.length > 0) {
        chartDate.unshift({
          date: getNextMonth(parseData(extract3ForChartingOutstandingBalance)),
          value: extractTextBetween(
            "Total  ",
            " Aggregated Outstanding Balances for Preceding 5 Months"
          )
            ?.split("   ")[1]
            .trim(),
        });

        setChartData(chartDate.reverse());
        setChartMaxValue(getMaxValue(chartDate));
      }
      // console.log(parseData(extract3ForChartingOutstandingBalance));

      // console.log(
      //   splitStringToRows(extract).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );

      // console.log(
      //   splitStringToRows(extract).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );
      const splitExactlyTwoSpaces = (inputString) => {
        // Split the string using the regular expression for exactly two spaces
        const splitArray = inputString.split(/(?<! )  (?! )/);
        return splitArray;
      };
      const combineSingleOrJoint = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (
            splitArray[i].startsWith("single") ||
            splitArray[i].startsWith("joint")
          ) {
            if (combinedArray.length > 0) {
              combinedArray[combinedArray.length - 1] += "  " + splitArray[i];
            } else {
              combinedArray.push(splitArray[i]);
            }
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };

      const combinePrivateResidentialPurchase = (splitArray) => {
        const combinedArray = [];

        for (let i = 0; i < splitArray.length; i++) {
          if (splitArray[i].includes("private residential purchase")) {
            combinedArray.push(
              splitArray[i] + "  " + (splitArray[i + 1] || "")
            );
            i++; // Skip the next element as it has been combined
          } else {
            combinedArray.push(splitArray[i]);
          }
        }

        return combinedArray;
      };
      // console.log(
      //   combineSingleOrJoint(
      //     combinePrivateResidentialPurchase(
      //       splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
      //     )
      //   ).filter(
      //     (row) =>
      //       row.includes("private residential purchase") ||
      //       row.includes("motor vehicle loan") ||
      //       row.includes("hdb loan")
      //   )
      // );

      setCBSImpLoanSupplementaryMonthlyInstallment(
        combineSingleOrJoint(
          combinePrivateResidentialPurchase(
            splitExactlyTwoSpaces(extract4ForChartingOutstandingBalance)
          )
        ).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );

      setMonthlyCommitment(
        parseFloat(
          extract2ForMonthlyCommitment
            .trim()
            .split("   ")
            [
              extract2ForMonthlyCommitment.trim().split("   ").length - 4
            ]?.replace(/,/g, "")
        ) +
          parseFloat(
            extract2ForMonthlyCommitment
              .trim()
              .split("   ")
              [
                extract2ForMonthlyCommitment.trim().split("   ").length - 3
              ]?.replace(/,/g, "")
          )
      );

      setCBSImpLoan(
        splitStringToRows(extract).filter(
          (row) =>
            row.includes("private residential purchase") ||
            row.includes("motor vehicle loan") ||
            row.includes("hdb loan")
        )
      );
    }
  }, [creditFile]);

  useEffect(() => {
    if (creditFile && creditFile !== "") {
      // console.log(
      //   extractTextBetween("Prob of Default between", " Probability of Default")
      //     .replace("%", "")
      //     .split(" to ")
      //     .map(parseFloat)
      // );
    }
  }, [creditFile]);

  const props = {
    action: "https://smart-lend.com/b-apply-loan",
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        console.log(file, fileList);
      }
    },
  };

  const [screenWidth, setScreenWidth] = useState(1920);
  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );
  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    getUserDetails();

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", "Bearer " + Cookies.get("token"));

      const options = {
        method: "GET",
        headers: headers,
      };

      try {
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/viewApplicantDetail",
          options
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data["applicantInfo"]);
        if (data["applicantInfo"][0].company_contact) {
          setCompanyContactNo(data["applicantInfo"][0].company_contact);
        }
        if (data["applicantInfo"][0].company_email) {
          setCompanyEmail(data["applicantInfo"][0].company_email);
        }
        setDirectorData(data["relationPersonelInfo"]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo, history]);

  const [activeTab, setActiveTab] = useState("Application Information");
  const [isLoading, setIsLoading] = useState(false);
  const [companyKeyman, setCompanyKeyman1] = useState("");

  useEffect(() => {
    if (CBSImpLoan !== null) {
      const item = { CBSImpLoan: CBSImpLoan };
      Cookies.set("CBSImpLoan", JSON.stringify(item));
    }
    if (monthlyCommitment !== null) {
      const item = { monthlyCommitment: monthlyCommitment };
      Cookies.set("monthlyCommitment", JSON.stringify(item));
    }
    if (chartData !== null) {
      const item = { chartData: chartData };
      Cookies.set("chartData", JSON.stringify(item));
    }
    if (enquiryDate !== null) {
      const item = { enquiryDate: enquiryDate };
      Cookies.set("enquiryDate", JSON.stringify(item));
    }
    if (chartMaxValue !== null) {
      const item = { chartMaxValue: chartMaxValue };
      Cookies.set("chartMaxValue", JSON.stringify(item));
    }
    if (cBSImpLoanSupplementaryMonthlyInstallment !== null) {
      const item = {
        cBSImpLoanSupplementaryMonthlyInstallment:
          cBSImpLoanSupplementaryMonthlyInstallment,
      };
      Cookies.set(
        "cBSImpLoanSupplementaryMonthlyInstallment",
        JSON.stringify(item)
      );
    }
    if (companyKeyman !== null && companyKeyman !== "") {
      const item = { companyKeyman: companyKeyman };
      Cookies.set("companyKeyman", JSON.stringify(item));
    }
    if (creditFile !== null) {
      const item = { creditFile: creditFile };
      Cookies.set("creditFile", JSON.stringify(item));
    }
    if (noFinancialReport === true) {
      const item = { noFinancialReport: noFinancialReport };
      Cookies.set("noFinancialReport", JSON.stringify(item));
    }
    if (corporateStructure === true) {
      const item = { corporateStructure: corporateStructure };
      Cookies.set("corporateStructure", JSON.stringify(item));
    }
    if (fileList.length > 0) {
      const item = { fileList: fileList };
      Cookies.set("fileList", JSON.stringify(item));
    }
    if (fileList2.length > 0) {
      const item = { fileList2: fileList2 };
      Cookies.set("fileList2", JSON.stringify(item));
    }
    if (fileList3.length > 0) {
      const item = { fileList3: fileList3 };
      Cookies.set("fileList3", JSON.stringify(item));
    }
    if (fileList4.length > 0) {
      const item = { fileList4: fileList4 };
      Cookies.set("fileList4", JSON.stringify(item));
    }
    if (fileList5.length > 0) {
      const item = { fileList5: fileList5 };
      Cookies.set("fileList5", JSON.stringify(item));
    }
    if (fileList6.length > 0) {
      const item = { fileList6: fileList6 };
      Cookies.set("fileList6", JSON.stringify(item));
    }
    if (fileList7.length > 0) {
      const item = { fileList7: fileList7 };
      Cookies.set("fileList7", JSON.stringify(item));
    }
    if (fileList8.length > 0) {
      const item = { fileList8: fileList8 };
      Cookies.set("fileList8", JSON.stringify(item));
    }
    if (fileList9.length > 0) {
      const item = { fileList9: fileList9 };
      Cookies.set("fileList9", JSON.stringify(item));
    }
    if (creditReportFile !== null && creditReportFile.length > 0) {
      const item = { creditReportFile: creditReportFile };
      Cookies.set("creditReportFile", JSON.stringify(item));
    }
  }, [
    creditFile,
    noFinancialReport,
    corporateStructure,
    fileList,
    fileList2,
    fileList3,
    fileList4,
    fileList5,
    fileList6,
    fileList7,
    fileList8,
    fileList9,
    creditReportFile,
    CBSImpLoan,
    monthlyCommitment,
    chartData,
    enquiryDate,
    chartMaxValue,
    cBSImpLoanSupplementaryMonthlyInstallment,
    companyKeyman,
  ]);

  useEffect(() => {
    if (Cookies.get("CBSImpLoan")) {
      const parseData = JSON.parse(Cookies.get("CBSImpLoan"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.CBSImpLoan) {
          setCBSImpLoan(parseData.CBSImpLoan);
        }
      }
    }
    if (Cookies.get("monthlyCommitment")) {
      const parseData = JSON.parse(Cookies.get("monthlyCommitment"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.monthlyCommitment) {
          setMonthlyCommitment(parseData.monthlyCommitment);
        }
      }
    }
    if (Cookies.get("chartData")) {
      const parseData = JSON.parse(Cookies.get("chartData"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.chartData) {
          setChartData(parseData.chartData);
        }
      }
    }
    if (Cookies.get("enquiryDate")) {
      const parseData = JSON.parse(Cookies.get("enquiryDate"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.enquiryDate) {
          setEnquiryDate(parseData.enquiryDate);
        }
      }
    }
    if (Cookies.get("chartMaxValue")) {
      const parseData = JSON.parse(Cookies.get("chartMaxValue"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.chartMaxValue) {
          setChartMaxValue(parseData.chartMaxValue);
        }
      }
    }
    if (Cookies.get("cBSImpLoanSupplementaryMonthlyInstallment")) {
      const parseData = JSON.parse(
        Cookies.get("cBSImpLoanSupplementaryMonthlyInstallment")
      );
      if (parseData) {
        // console.log(parseData);
        if (parseData.cBSImpLoanSupplementaryMonthlyInstallment) {
          setCBSImpLoanSupplementaryMonthlyInstallment(
            parseData.cBSImpLoanSupplementaryMonthlyInstallment
          );
        }
      }
    }
    if (Cookies.get("companyKeyman")) {
      const parseData = JSON.parse(Cookies.get("companyKeyman"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.companyKeyman) {
          setCompanyKeyman1(parseData.companyKeyman);
        }
      }
    }
    if (Cookies.get("fileList")) {
      const parseData = JSON.parse(Cookies.get("fileList"));
      if (parseData) {
        // console.log(parseData);
        if (parseData.fileList) {
          setFileList(parseData.fileList);
        }
      }
    }
    if (Cookies.get("fileList2")) {
      const parseData = JSON.parse(Cookies.get("fileList2"));
      if (parseData) {
        if (parseData.fileList2) {
          setFileList2(parseData.fileList2);
        }
      }
    }
    if (Cookies.get("fileList3")) {
      const parseData = JSON.parse(Cookies.get("fileList3"));
      if (parseData) {
        if (parseData.fileList3) {
          setFileList3(parseData.fileList3);
        }
      }
    }
    if (Cookies.get("fileList4")) {
      const parseData = JSON.parse(Cookies.get("fileList4"));
      if (parseData) {
        if (parseData.fileList4) {
          setFileList4(parseData.fileList4);
        }
      }
    }
    if (Cookies.get("fileList5")) {
      const parseData = JSON.parse(Cookies.get("fileList5"));
      if (parseData) {
        if (parseData.fileList5) {
          setFileList5(parseData.fileList5);
        }
      }
    }
    if (Cookies.get("fileList6")) {
      const parseData = JSON.parse(Cookies.get("fileList6"));
      if (parseData) {
        if (parseData.fileList6) {
          setFileList6(parseData.fileList6);
        }
      }
    }
    if (Cookies.get("fileList7")) {
      const parseData = JSON.parse(Cookies.get("fileList7"));
      if (parseData) {
        if (parseData.fileList7) {
          setFileList7(parseData.fileList7);
        }
      }
    }
    if (Cookies.get("fileList8")) {
      const parseData = JSON.parse(Cookies.get("fileList8"));
      if (parseData) {
        if (parseData.fileList8) {
          setFileList8(parseData.fileList8);
        }
      }
    }
    const convertUrlToFile = async (url, fileName) => {
      const response = await fetch(url); // Fetch the file from the URL
      const blob = await response.blob(); // Convert the response into a Blob

      // Optionally convert the Blob to a File if needed
      const file = new File([blob], fileName, { type: blob.type });

      return file; // Return the File object
    };

    if (Cookies.get("fileList9")) {
      const parseData = JSON.parse(Cookies.get("fileList9"));
      if (parseData) {
        if (parseData.fileList9) {
          setFileList9(parseData.fileList9);
          // console.log(parseData.fileList9);

          for (const file of parseData.fileList9) {
            // console.log(file);
            convertUrlToFile(file.url, file.name)
              .then((fileBlob) => {
                setCreditReportFile(fileBlob);
                extractText(fileBlob); // Pass the Blob or File to extractText
              })
              .catch((err) =>
                console.error("Error converting URL to file:", err)
              );
          }
        }
      }
    }
    if (Cookies.get("creditFile")) {
      const parseData = JSON.parse(Cookies.get("creditFile"));
      if (parseData) {
        if (parseData.creditFile) {
          setCreditFile(parseData.creditFile);
        }
      }
    }
    if (Cookies.get("noFinancialReport")) {
      const parseData = JSON.parse(Cookies.get("noFinancialReport"));
      if (parseData) {
        if (parseData.noFinancialReport) {
          setNoFinancialReport(parseData.noFinancialReport);
        }
      }
    }
    if (Cookies.get("corporateStructure")) {
      const parseData = JSON.parse(Cookies.get("corporateStructure"));
      if (parseData) {
        if (parseData.corporateStructure) {
          setCorporateStructure(parseData.corporateStructure);
        }
      }
    }
  }, []);

  const handleSubmit = async (companyContactNo, companyEmail) => {
    Cookies.remove("applyLoanForm");
    Cookies.remove("CBSImpLoan");
    Cookies.remove("monthlyCommitment");
    Cookies.remove("chartData");
    Cookies.remove("enquiryDate");
    Cookies.remove("chartMaxValue");
    Cookies.remove("cBSImpLoanSupplementaryMonthlyInstallment");
    Cookies.remove("companyKeyman");
    Cookies.remove("creditFile");
    Cookies.remove("noFinancialReport");
    Cookies.remove("corporateStructure");
    Cookies.remove("fileList");
    Cookies.remove("fileList2");
    Cookies.remove("fileList3");
    Cookies.remove("fileList4");
    Cookies.remove("fileList5");
    Cookies.remove("fileList6");
    Cookies.remove("fileList7");
    Cookies.remove("fileList8");
    Cookies.remove("fileList9");
    Cookies.remove("creditReportFile");
    setIsLoading(true);
    setIsModalOpen(false);
    // console.log("companyContactNo:", companyContactNo);
    // console.log("companyEmail:", companyEmail);

    try {
      // Code to check if all files are uploaded
      if (![fileList].every((fileList) => fileList.length > 0)) {
        message.error("All files must be uploaded before proceeding");
        return;
      } else {
        message.success("Your loan application are being processed");

        let item = {
          express: 0,
          type: loanType,
          category: loanCategory,
          request_amount: parseFloat(requestingLoanAmount),
          request_interest_rate: parseFloat(expectingInterestRate) / 100,
          request_period: parseInt(loanPeriod),
          request_principal: parseFloat(request_principal),
          request_monthly_installment: parseFloat(request_monthly_installment),
          admin_fee: parseFloat(adminFee),
          total_admin_fee: parseFloat(adminFee * requestingLoanAmount),
          total_interest: parseFloat(request_revenue),
          visibility: 0,
          disbursed_amount: parseFloat(requestingLoanAmount * (1 - adminFee)),
          keyman: companyKeyman,
          credit_score:
            creditFile && creditFile !== ""
              ? parseFloat(
                  extractTextBetween(
                    "financial institution.  Score",
                    "Risk Grade"
                  )
                )
              : null,
          corporate_structure: corporateStructure,
        };
        if (requestingLoanAmount < 500000 && !corporateStructure) {
          item.visibility = 1;
        }
        const response = await fetch(
          "https://www.smart-lend.com/api/loan/createLoan",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify(item),
          }
        );
        const data = await response.json();

        const zip = new JSZip();

        // concatenate all file lists

        const allFiles = fileList.concat(
          fileList2,
          fileList3,
          fileList4,
          fileList5,
          fileList6,
          fileList7,
          fileList8,
          fileList9
        );

        const fetchBlobAndReadAsBase64 = async (file) => {
          const response = await fetch(file.url);

          if (!response.ok) {
            throw new Error(`Failed to fetch the file: ${response.statusText}`);
          }
          const buffer = await response.arrayBuffer();
          const blob = new Blob([buffer]);
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              resolve(event.target.result.split(",")[1]); // Get base64 part of the data URL
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(blob);
          });
        };

        const addFilesToZip = async () => {
          for (const file of allFiles) {
            const fileContent = await fetchBlobAndReadAsBase64(file);
            zip.file(file.name, fileContent, { base64: true });
          }

          const content = await zip.generateAsync({ type: "blob" });
          return content;
        };

        addFilesToZip().then(async (content) => {
          // saveAs(content, "bank_statement.zip");

          const formData = new FormData();
          formData.append("bank_statement", content, "bank_statement.zip");
          formData.append("loan_id", data.loan_request.loan_id); // Access loan_id from data.loan_request.loan_id
          formData.append(
            "entity_name",
            registeredBusinessName.replace(/[^\w]/g, "")
          ); // Access loan_id from data.loan_request.loan_id

          try {
            const uploadResponse = await fetch(
              "https://www.smart-lend.com/api/loan/uploadBankStatement",
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + Cookies.get("token"),
                },
                body: formData,
              }
            );
            const formData1 = new FormData();
            formData1.append("credit_report", creditReportFile);
            const creditReportData = {
              EnquiryDate:
                creditFile && creditFile !== "" && enquiryDate
                  ? enquiryDate
                  : null,
              SecuredCreditLimit:
                creditFile && creditFile !== ""
                  ? parseFloat(
                      extractTextBetween(
                        "Secured Credit Limit",
                        "Unsecured Credit Limit"
                      ).replace(/,/g, "")
                    )
                  : null,
              UnsecuredCreditLimit:
                creditFile && creditFile !== ""
                  ? parseFloat(
                      extractTextBetween(
                        "Unsecured Credit Limit",
                        "Exempted Credit Limit"
                      ).replace(/,/g, "")
                    )
                  : null,
              CreditScore:
                creditFile && creditFile !== ""
                  ? parseFloat(
                      extractTextBetween(
                        "financial institution.  Score",
                        "Risk Grade"
                      )
                    )
                  : null,
              RiskGrade:
                creditFile && creditFile !== ""
                  ? extractTextBetween("Risk Grade ", "Risk Grade Description")
                  : null,
              ProbabilityofDefault:
                creditFile && creditFile !== ""
                  ? parseFloat(
                      extractTextBetween(
                        "Probability of Default ",
                        " For more details on the description"
                      ).replace(/,/g, "")
                    )
                  : null,
              SecuredCreditUtilized:
                creditFile && creditFile !== ""
                  ? extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
                  : null,
              UnsecuredCreditUtilized:
                creditFile && creditFile !== ""
                  ? extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
                  : null,
              SecuredCreditUtilizationinpercentage:
                creditFile && creditFile !== ""
                  ? (
                      (extractTextBetween(
                        "Total  ",
                        " Aggregated Outstanding Balances for Preceding 5 Months"
                      )
                        ?.split("  ")
                        ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                        parseFloat(
                          extractTextBetween(
                            "Secured Credit Limit",
                            "Unsecured Credit Limit"
                          )?.replace(/,/g, "")
                        )) *
                      100
                    )?.toFixed(2)
                  : null,
              UnsecuredCreditUtilizationinpercentage:
                creditFile && creditFile !== ""
                  ? (
                      (extractTextBetween(
                        "Total  ",
                        " Aggregated Outstanding Balances for Preceding 5 Months"
                      )
                        ?.split("  ")
                        ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                        parseFloat(
                          extractTextBetween(
                            "Unsecured Credit Limit",
                            "Exempted Credit Limit"
                          )?.replace(/,/g, "")
                        )) *
                      100
                    )?.toFixed(2)
                  : null,
              MonthlyCommitment:
                creditFile && creditFile !== "" && monthlyCommitment
                  ? monthlyCommitment.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })
                  : null,
              NoteworthyOngoingLoans:
                CBSImpLoan && CBSImpLoan.length !== 0 ? CBSImpLoan : null,
              Last6MonthsGraphChartMaxData:
                chartMaxValue && chartData ? chartMaxValue : null,
              Last6MonthsGraphChartData:
                chartMaxValue && chartData ? chartData : null,
              CBSImpLoanSupplementaryInstallment:
                cBSImpLoanSupplementaryMonthlyInstallment
                  ? cBSImpLoanSupplementaryMonthlyInstallment
                  : null,
            };
            formData1.append(
              "credit_report_data",
              JSON.stringify(creditReportData)
            );

            const updateBorrowerCreditReport = await fetch(
              "https://www.smart-lend.com/api/user/borrowerUpdateCreditReport",
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + Cookies.get("token"),
                },
                body: formData1,
              }
            );

            const updateBorrowerCreditReportasd =
              await updateBorrowerCreditReport.json();

            const uploadData = await uploadResponse.json(); // 'data' is renamed to 'uploadData'

            if (response.status === 200) {
              message.success("Loan application submitted successfully!");
              message.success("Bank statement uploaded successfully.");
            }

            if (response.status !== 200) {
              message.error(
                "Error submitted loan request, please sync your singpass "
              );
              setIsLoading(false);
            }

            const date = new Date();
            const options = {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            };

            const formattedDate =
              date.toLocaleDateString("en-GB", options) +
              ", " +
              date.toLocaleTimeString("en-GB", options);

            if (response.status === 200) {
              try {
                const response = await fetch(
                  `https://www.smart-lend.com/api/profile/createActivityLog`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("token"),
                    },
                    body: JSON.stringify({
                      user_id: userData.user_id,
                      user_name: userData.entity_name,
                      user_account_type: "borrower",
                      description: `You have applied for a loan for SGD ${requestingLoanAmount.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "SGD",
                        }
                      )} on ${formattedDate}`,
                    }),
                  }
                );
                const data = await response.json();
                // console.log("Offer created:", data);
                setIsLoading(false);
              } catch (error) {
                console.error("Error creating offer:", error);
                setIsLoading(false);
              }
            }

            if (companyEmail || companyContactNo !== null) {
              try {
                const response = await fetch(
                  `https://www.smart-lend.com/api/loan/updateApplicantDetail?company_email=${companyEmail}&company_contact=${companyContactNo}`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + Cookies.get("token"),
                    },
                  }
                );
                const data = await response.json();
                message.success("Applicant Detail updated!");
                // console.log("update applicant detail", data);
                setIsLoading(false);
              } catch (error) {
                console.log("error to upadate applicant detail", error);
                setIsLoading(false);
              }
            }

            // console.log("Offer created:", data);
            if (!data.authorizations) {
              setRedirectTo("/b-requested-loans"); // set the path to the state variable
            } else {
              setRedirectTo(
                `/b-requested-loans?authorizationrequired=true&loanid=${data.loan_request.loan_id}`
              ); // set the path to the state variable
            }
          } catch (error) {
            console.error("Error uploading bank statement:", error);
            message.error("Error submitted loan request statement");
            message.error("Error uploading bank statement");
            setIsLoading(false);
          }
        });
      }
    } catch (error) {
      console.error("Error creating offer:", error);
      message.error("Error submitted loan request ");
      setIsLoading(false);
    }
  };

  const isLessThanOneMonthAgo = (dateString) => {
    // Parse the input date string (assuming format is "DD/MM/YYYY")
    const [day, month, year] = dateString.split("/").map(Number);
    const inputDate = new Date(year, month - 1, day); // JavaScript months are 0-based

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in months and years
    const yearDiff = currentDate.getFullYear() - inputDate.getFullYear();
    const monthDiff = currentDate.getMonth() - inputDate.getMonth();

    // Check if the input date is within the last month
    if (yearDiff === 0 && monthDiff <= 1) {
      if (monthDiff === 1) {
        // If the difference is exactly 1 month, check the day to determine if it's within 30/31 days
        const daysDiff = currentDate.getDate() - inputDate.getDate();
        return daysDiff <= 0;
      }
      return true;
    } else if (
      yearDiff === 1 &&
      currentDate.getMonth() === 0 &&
      inputDate.getMonth() === 11
    ) {
      // Special case for dates in December of the previous year and January of the current year
      return currentDate.getDate() <= inputDate.getDate();
    }

    return false;
  };

  const handleNextTab = () => {
    if (activeTab === "Application Information" && corporateStructure) {
      setActiveTab("Financial Profile");
    } else if (activeTab === "Application Information" && !corporateStructure) {
      setActiveTab("Company Keyman");
    }
    if (activeTab === "Company Keyman") {
      setActiveTab("Financial Profile");
    }
    // if (activeTab === "Financial Profile") {
    //   setActiveTab("Related Personal Information");
    // }
    if (activeTab === "Financial Profile") {
      setActiveTab("Credit Report");
    }
    if (activeTab === "Credit Report") {
      setActiveTab("Credit Report Upload");
    }
    if (activeTab === "Credit Report Upload") {
      setActiveTab("Authorization");
    }
    // if (activeTab === "Related Personal Information") {
    //   setActiveTab("Authorization");
    // }
    // if (activeTab === "Tax Declaration") {
    //   setActiveTab("Loan Request");
    // }
    // if (activeTab === "Loan Request") {
    //   setActiveTab("Authorization");
    // }
  };

  const handleBackTab = () => {
    if (activeTab === "Financial Profile" && !corporateStructure) {
      setActiveTab("Company Keyman");
    } else if (activeTab === "Financial Profile" && corporateStructure) {
      setActiveTab("Application Information");
    }
    if (activeTab === "Company Keyman") {
      setActiveTab("Application Information");
    }
    if (activeTab === "Related Personal Information") {
      setActiveTab("Financial Profile");
    }
    // if (activeTab === "Tax Declaration") {
    //   setActiveTab("Related Personal Information");
    // }
    // if (activeTab === "Loan Request") {
    //   setActiveTab("Related Personal Information");
    // }
    // if (activeTab === "Authorization") {
    //   setActiveTab("Related Personal Information");
    // }
    if (activeTab === "Credit Report") {
      setActiveTab("Credit Report Upload");
    }
    if (activeTab === "Credit Report Upload") {
      setActiveTab("Financial Profile");
    }
    if (activeTab === "Authorization") {
      setActiveTab("Credit Report");
    }
  };

  useEffect(() => {
    if (data[0]?.borrower_name) {
      const nameParts = data[0]?.borrower_name.split(" ");

      if (nameParts.length === 2) {
        setFirstName(nameParts[0]);
        setLastName(nameParts[1]);
      }
    }
  }, [data]);

  const getPreviousMonth = (currentDate, backed) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    let resultMonth;

    if (currentDay >= 12) {
      // If it's December and the day is 12th or later, return November
      const oneMonthAgo = (currentMonth - (1 + backed) + 12) % 12;
      resultMonth = monthNames[oneMonthAgo];
    } else {
      // If it's before December or on December but before the 12th, return 2 months before
      const twoMonthsAgo = (currentMonth - (2 + backed) + 12) % 12;
      resultMonth = monthNames[twoMonthsAgo];
    }

    return resultMonth;
  };

  const getPreviousYear = (yearsAgo) => {
    const currentYear = new Date().getFullYear();

    if (yearsAgo === 1) {
      return currentYear - 1;
    } else if (yearsAgo === 2) {
      return currentYear - 2;
    } else {
      // Handle other cases if needed
      return currentYear;
    }
  };

  const ApplicationInformation = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        padding: "2.5% 5%",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: isMobile ? "100%" : "50%" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          First Name
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={firstName}
            disabled={!!data[0]?.borrower_name}
            placeholder="First Name"
            onChange={(event) => setFirstName(event.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          width: isMobile ? "100%" : "50%",
          marginTop: isMobile ? "20px" : "0px",
        }}
      >
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Last Name
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={lastName}
            disabled={!!data[0]?.borrower_name}
            placeholder="Last Name"
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Residential Status
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            disabled
            value={data[0]?.residential_status || residentialStatus}
            placeholder="Residential Status"
            onChange={(event) => setResidentalStatus(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Nationality
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            disabled
            value={data[0]?.nationality || nationality}
            placeholder="Nationality"
            onChange={(event) => setNationality(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Email</div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            disabled
            value={data[0]?.email || email}
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Contact No.
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            type="number"
            value={data[0]?.contact_no || contactNo}
            disabled={!!data[0]?.contact_no}
            placeholder="Contact No."
            onChange={(event) => {
              // Prevent negative values and limit to 10 digits
              const inputValue = event.target.value;
              if (inputValue >= 0 && String(inputValue).length <= 10) {
                setContactNo(inputValue);
              }
            }}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Address
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={data[0]?.registered_address || registeredAddress}
            disabled
            placeholder="Registered Address"
            onChange={(event) => setRegisteredAddress(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Business Name
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={data[0]?.registered_business_name || registeredBusinessName}
            disabled
            placeholder="Registered Business Name"
            onChange={(event) => setRegisteredBusinessName(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Country of Incorporation
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={data[0]?.country_of_incorporation || countryOfIncorporation}
            disabled
            placeholder="Country of Incorporation"
            onChange={(event) => setCountryOfIncorporation(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registration Date
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={data[0]?.registration_date || registrationDate}
            disabled
            placeholder="Registration Date"
            onChange={(event) => setRegistrationDate(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>UEN</div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={data[0]?.uen || UEN}
            disabled
            placeholder="UEN"
            onChange={(event) => setUEN(event.target.value)}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Business Address
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            value={
              data[0]?.registered_business_address || registeredBusinessAddress
            }
            disabled
            placeholder="Registered Business Address"
            onChange={(event) =>
              setRegisteredBusinessAddress(event.target.value)
            }
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Company Contact No.
          <span style={{ color: "red", fontSize: 20 }}>*</span>
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            required
            type="number"
            // value={companyContactNo || data[0]?.company_contact}
            value={companyContactNo}
            placeholder="Company Contact No."
            onChange={(event) => {
              // Prevent negative values and limit to 10 digits
              const inputValue = event.target.value;
              if (inputValue >= 0 && String(inputValue).length <= 10) {
                setCompanyContactNo(inputValue);
              }
            }}
          />
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Company Email
          <span style={{ color: "red", fontSize: 20 }}> *</span>
        </div>
        <div>
          <input
            style={{
              width: "90%",
              padding: "8px",
              borderRadius: "12px",
              marginTop: "5px",
              outline: "none",
              border: "1px solid rgba(0,0,0,0.2)",
            }}
            required
            type="email"
            // value={companyEmail || data[0]?.company_email}
            value={companyEmail}
            placeholder="Company Email"
            onChange={(event) => setCompanyEmail(event.target.value)}
          />
        </div>
      </div>
    </div>
  );
  const { Dragger } = Upload;
  const handleChange = (info) => {
    // console.log(info);
    const newFileList = info.fileList.map((file) => {
      if (file.originFileObj) {
        return {
          ...file,
          status: "done",
          url: URL.createObjectURL(file.originFileObj),
        };
      }
      return file;
    });

    setFileList(newFileList);
  };
  const handleChange2 = async (info) => {
    const newFileList = await Promise.all(
      info.fileList.map(async (file) => {
        if (file.originFileObj) {
          if (!file.url) {
            const url = await UniversaleUploadFile(file.originFileObj); // Wait for URL
            return {
              ...file,
              status: "done",
              url: url, // Set the resolved URL
              actualFile: info.file,
            };
          }
        }
        return file;
      })
    );
    setFileList7(newFileList);
  };
  const FinancialProfile = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        padding: "2.5% 5%",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Please Upload Your Latest 6 Month Bank Statement
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "500",
            fontSize: 11,
          }}
        >
          If you have multiple business accounts, attach all bank statements for
          the same month in the relevant monthly field.
        </div>
      </div>

      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "25px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 0)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "20px" }}>
        <div>
          <Upload
            fileList={fileList}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    // console.log(file);
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "15px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 1)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "10px" }}>
        <div>
          <Upload
            fileList={fileList2}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList2(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "15px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 2)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "10px" }}>
        <div>
          <Upload
            fileList={fileList3}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList3(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "15px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 3)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "10px" }}>
        <div>
          <Upload
            fileList={fileList4}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList4(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "15px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 4)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "10px" }}>
        <div>
          <Upload
            fileList={fileList5}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList5(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "20%", marginTop: "15px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "600" }}>
          {getPreviousMonth(new Date(), 5)} Statement
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "10px" }}>
        <div>
          <Upload
            fileList={fileList6}
            multiple
            beforeUpload={(file) => {
              return false;
            }}
            onChange={async (info) => {
              const newFileList = await Promise.all(
                info.fileList.map(async (file) => {
                  if (file.originFileObj) {
                    if (!file.url) {
                      const url = await UniversaleUploadFile(
                        file.originFileObj
                      ); // Wait for URL
                      return {
                        ...file,
                        status: "done",
                        url: url, // Set the resolved URL
                        actualFile: info.file,
                      };
                    }
                  }
                  return file;
                })
              );
              setFileList6(newFileList);
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            marginTop: "60px",
          }}
        >
          Please upload your latest 2 years financial report
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "500",
            fontSize: 11,
          }}
        >
          Tip: Submitting comprehensive financial reports can increase your
          chances of loan approval and potentially secure a higher loan amount.
          Lenders use this information to better assess your business's
          financial health.
        </div>
      </div>
      {/* <div style={{ width: "20%", marginTop: "30px" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              {getPreviousYear(1)} financial report
            </div>
          </div> */}
      {!noFinancialReport ? (
        <div style={{ width: isMobile ? "100%" : "80%", marginTop: "25px" }}>
          <div>
            <Dragger
              beforeUpload={(file) => {
                return false;
              }}
              fileList={fileList7}
              onChange={handleChange2}
              onPreview={async (file) => {
                // Use window.open to open the file URL in a new tab
                window.open(file.url);
              }}
              multiple
            >
              <div style={{ padding: "30px 0px" }}>
                <div>
                  <ArchiveIcon
                    style={{ fontSize: 50, color: "rgb(25, 108, 176)" }}
                  />
                </div>
                <div
                  style={{
                    color: "rgb(100,100,100)",
                    fontWeight: 500,
                    fontSize: 15,
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  Upload files
                </div>
                <div
                  style={{
                    color: "rgb(100,100,100)",
                    fontWeight: 400,
                    fontSize: 10,
                    width: "70%",
                    marginLeft: "15%",
                  }}
                >
                  You can upload by dragging your files here or you can also
                  click here to prompt and upload.
                </div>
              </div>
              {/* <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button> */}
            </Dragger>
          </div>
        </div>
      ) : null}
      <div
        style={{
          color: "rgba(0,0,0,0.5)",
          fontWeight: "500",
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          setNoFinancialReport(!noFinancialReport);
        }}
      >
        <input
          type="checkbox"
          checked={noFinancialReport}
          onChange={(e) => {
            console.log(e.target.checked);
            setNoFinancialReport(e.target.checked);
            if (e.target.checked) {
              setFileList7([]);
            }
          }}
        />
        My financial report is not ready yet
      </div>
    </div>
  );

  function extractText(file) {
    pdfToText(file)
      .then((text) => {
        console.log(text);
        setCreditFile(text);
      })
      .catch((error) => {
        console.error("Failed to extract text from pdf");
      });
  }

  const CreditReportUpload = (
    <div
      style={{
        width: "90%",
        padding: "2.5% 5%",
      }}
    >
      <div style={{ width: "100%", marginTop: isMobile ? "20px" : "0px" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
          }}
        >
          Please upload your retrieved Credit Report
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "25px" }}>
        <div>
          {/* <input type="file" onChange={(e)=>{console.log(e.target.files[0])}}/> */}
          <Upload
            fileList={fileList9}
            beforeUpload={async (files) => {
              // Check if multiple files are being uploaded
              const fileArray = Array.isArray(files) ? files : [files]; // Ensure it's an array
              const uploadedFiles = await Promise.all(
                fileArray.map(async (file) => {
                  if (!file.url) {
                    const url = await UniversaleUploadFile(file); // Wait for each file upload

                    // Return the file object with URL and necessary attributes
                    return {
                      uid: file.uid,
                      name: file.name,
                      status: "done",
                      url: url,
                      actualFile: file,
                    };
                  }
                })
              );

              // Update the file list state with the uploaded files
              setFileList9((prevFileList) => [
                ...prevFileList,
                ...uploadedFiles,
              ]);

              // Process each file individually, if needed
              for (const file of fileArray) {
                setCreditReportFile(file);
                console.log(file);
                extractText(file);
              }

              // Prevent the default upload behavior
              return false;
            }}
            onPreview={async (file) => {
              if (file.url) {
                window.open(file.url, "_blank"); // Open the URL in a new tab
              } else {
                // Handle cases where URL is not available yet
                console.log("No URL available for preview.");
              }
            }}
            onRemove={(file) => {
              setFileList9([]); // This assumes you're only handling one file at a time.
              setCreditReportFile(null);
              setCreditFile(null);
            }}
          >
            <Button
              style={{
                width: "100%",
                borderRadius: "10px",
                marginBottom: "5px",
              }}
              icon={<UploadOutlined />}
              // onClick={(event) => handleUpload( event)}
            >
              Upload
            </Button>
          </Upload>
        </div>
      </div>

      {/* {fileList9 &&
          fileList9.length > 0 && ? (
            <div style={{ color: "red" }}>
              "CBS report not detected, please do not alter or try to adjust the
              report."
            </div>
          ) : null} */}

      {fileList9 && fileList9.length > 0 && creditFile && creditFile !== "" ? (
        isNaN(
          parseFloat(
            extractTextBetween(
              "Secured Credit Limit",
              "Unsecured Credit Limit"
            ).replace(/,/g, "")
          )
        ) ? (
          <div style={{ color: "red" }}>
            CBS Report not detected, please do not alter CBS report data
          </div>
        ) : enquiryDate && isLessThanOneMonthAgo(enquiryDate) ? (
          <div style={{ fontSize: 12, width: "100%" }}>
            <div style={{ marginTop: "50px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Enquiry Date:
              </div>
              {creditFile && creditFile !== "" && enquiryDate
                ? convertDate(enquiryDate)
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Secured Credit Limit:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    parseFloat(
                      extractTextBetween(
                        "Secured Credit Limit",
                        "Unsecured Credit Limit"
                      ).replace(/,/g, "")
                    )
                  )
                  ? parseFloat(
                      extractTextBetween(
                        "Secured Credit Limit",
                        "Unsecured Credit Limit"
                      ).replace(/,/g, "")
                    )?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Unsecured Credit Limit:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    parseFloat(
                      extractTextBetween(
                        "Unsecured Credit Limit",
                        "Exempted Credit Limit"
                      ).replace(/,/g, "")
                    )
                  )
                  ? parseFloat(
                      extractTextBetween(
                        "Unsecured Credit Limit",
                        "Exempted Credit Limit"
                      ).replace(/,/g, "")
                    )?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Credit Score:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? extractTextBetween(
                    "financial institution.  Score",
                    "Risk Grade"
                  )
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Risk Grade:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? extractTextBetween("Risk Grade ", "Risk Grade Description")
                : null}
              <tooltip
                placement="topRight"
                title="If your credit risk rating is below GG, funding options might be limited."
              >
                <button
                  className="all_button"
                  style={{
                    borderRadius: "15px",
                    height: "15px",
                    width: "15px",
                    background: "transparent",
                    border: "1px solid rgba(0,0,0,0.6)",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 0px 5px 5px",
                    alignItems: "center",
                  }}
                >
                  <QuestionOutlined style={{ fontSize: "8px" }} />
                </button>
              </tooltip>
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Probability of Default:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    parseFloat(
                      extractTextBetween(
                        "Probability of Default ",
                        " For more details on the description"
                      ).replace(/,/g, "")
                    )
                  )
                  ? parseFloat(
                      extractTextBetween(
                        "Probability of Default ",
                        " For more details on the description"
                      ).replace(/,/g, "")
                    ) + "%"
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Secured Credit Utilized:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
                  )
                  ? extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[0]
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Unsecured Credit Utilized:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
                  )
                  ? extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[1]
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "SGD",
                      })
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Secured Credit Utilization in percentage:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                      parseFloat(
                        extractTextBetween(
                          "Secured Credit Limit",
                          "Unsecured Credit Limit"
                        )?.replace(/,/g, "")
                      )
                  )
                  ? (
                      (extractTextBetween(
                        "Total  ",
                        " Aggregated Outstanding Balances for Preceding 5 Months"
                      )
                        ?.split("  ")
                        ?.map((num) => parseFloat(num.replace(/,/g, "")))[0] /
                        parseFloat(
                          extractTextBetween(
                            "Secured Credit Limit",
                            "Unsecured Credit Limit"
                          )?.replace(/,/g, "")
                        )) *
                      100
                    )?.toFixed(2) + "%"
                  : "Not Applicable"
                : null}
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Unsecured Credit Utilization in percentage:{" "}
              </div>
              {creditFile && creditFile !== ""
                ? !isNaN(
                    extractTextBetween(
                      "Total  ",
                      " Aggregated Outstanding Balances for Preceding 5 Months"
                    )
                      ?.split("  ")
                      ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                      parseFloat(
                        extractTextBetween(
                          "Unsecured Credit Limit",
                          "Exempted Credit Limit"
                        )?.replace(/,/g, "")
                      )
                  )
                  ? (
                      (extractTextBetween(
                        "Total  ",
                        " Aggregated Outstanding Balances for Preceding 5 Months"
                      )
                        ?.split("  ")
                        ?.map((num) => parseFloat(num.replace(/,/g, "")))[1] /
                        parseFloat(
                          extractTextBetween(
                            "Unsecured Credit Limit",
                            "Exempted Credit Limit"
                          )?.replace(/,/g, "")
                        )) *
                      100
                    )?.toFixed(2) + "%"
                  : "Not Applicable"
                : null}
              <tooltip
                placement="topRight"
                title="A healthy credit utilization should be below 65%."
              >
                <button
                  className="all_button"
                  style={{
                    borderRadius: "15px",
                    height: "15px",
                    width: "15px",
                    background: "transparent",
                    border: "1px solid rgba(0,0,0,0.6)",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 0px 5px 5px",
                    alignItems: "center",
                  }}
                >
                  <QuestionOutlined style={{ fontSize: "8px" }} />
                </button>
              </tooltip>
            </div>
            <div style={{ marginTop: "15px", width: "100%", display: "flex" }}>
              <div style={{ fontSize: 13, fontWeight: 600, width: "50%" }}>
                Monthly Commitment/Installment Secured:{" "}
              </div>
              {creditFile && creditFile !== "" && monthlyCommitment
                ? monthlyCommitment.toLocaleString("en-US", {
                    style: "currency",
                    currency: "SGD",
                  })
                : (0).toLocaleString("en-US", {
                  style: "currency",
                  currency: "SGD",
                })}
            </div>
            {CBSImpLoan && CBSImpLoan.length !== 0 ? (
              <div style={{ width: "100%", marginTop: " 25px" }}>
                <div style={{ fontSize: 13, fontWeight: 600, width: "100%" }}>
                  Note-worthy On-going Loans
                </div>
                <div style={{ marginTop: "15px", width: "100%" }}>
                  <div
                    style={{
                      borderRadius: "5px",
                      overflow: "hidden",
                      border: "1px solid lightgrey",
                      marginTop: "10px",
                      fontSize: 14,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        background: "rgba(217, 238, 255, 0.5)",
                        borderBottom: "1px solid lightgrey",
                        fontWeight: 600,
                      }}
                    >
                      <div
                        style={{
                          width: "calc(30% - 10px)",
                          padding: "5px",
                        }}
                      >
                        Loan / Product Type
                      </div>
                      <div
                        style={{
                          width: "calc(25% - 10px)",
                          padding: "5px",
                        }}
                      >
                        Grantor / Bank
                      </div>
                      <div
                        style={{
                          width: "calc(25% - 10px)",
                          padding: "5px",
                        }}
                      >
                        Outstanding Balance
                      </div>
                      <div
                        style={{
                          width: "calc(20% - 10px)",
                          padding: "5px",
                        }}
                      >
                        Monthly Installment
                      </div>
                    </div>
                    {CBSImpLoan.map((loan, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          borderBottom:
                            index === CBSImpLoan.length - 1
                              ? "none"
                              : "1px solid lightgrey",
                          fontSize: 12,
                        }}
                      >
                        <div
                          style={{
                            width: "calc(30% - 10px)",
                            padding: "3.5px 5px",
                          }}
                        >
                          {loan.includes("private residential purchase", "")
                            ? "Private Residential Purchase"
                            : loan.includes("motor vehicle loan", "")
                            ? "Motor Vehicle Loan"
                            : loan.includes("hdb loan", "")
                            ? "HDB Loan"
                            : ""}
                        </div>
                        <div
                          style={{
                            width: "calc(25% - 10px)",
                            padding: "3.5px 5px",
                          }}
                        >
                          {loan
                            .replace("private residential purchase", "")
                            .replace("motor vehicle loan", "")
                            .replace("hdb loan", "")
                            .trim()
                            .split(" ")[0]
                            .toUpperCase()}
                        </div>
                        <div
                          style={{
                            width: "calc(25% - 10px)",
                            padding: "3.5px 5px",
                          }}
                        >
                          {parseFloat(
                            loan
                              .split("   ")
                              [loan.split("   ").length - 4].replace(/,/g, "")
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </div>
                        <div
                          style={{
                            width: "calc(20% - 10px)",
                            padding: "3.5px 5px",
                          }}
                        >
                          {cBSImpLoanSupplementaryMonthlyInstallment?.[index]
                            ? (
                                parseFloat(
                                  cBSImpLoanSupplementaryMonthlyInstallment[
                                    index
                                  ]
                                    .split("   ")
                                    [
                                      cBSImpLoanSupplementaryMonthlyInstallment[
                                        index
                                      ].split("   ").length - 4
                                    ]?.replace(/,/g, "")
                                ) +
                                parseFloat(
                                  cBSImpLoanSupplementaryMonthlyInstallment[
                                    index
                                  ]
                                    .split("   ")
                                    [
                                      cBSImpLoanSupplementaryMonthlyInstallment[
                                        index
                                      ].split("   ").length - 3
                                    ]?.replace(/,/g, "")
                                )
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : null}
                          {/* {parseFloat(
                                loan
                                  .split("   ")
                                  [loan.split("   ").length - 4].replace(
                                    /,/g,
                                    ""
                                  )
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })} */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
            {chartMaxValue && chartData ? (
              <div style={{ width: "100%", marginTop: " 25px" }}>
                <div style={{ fontSize: 13, fontWeight: 600, width: "100%" }}>
                  Last 6 Months (Unsecured) Credit Utilization Graph
                </div>
                <div style={{ marginTop: "15px", width: "100%", fontSize: 10 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "5%", position: "relative" }}>
                      <div style={{ height: "15px" }}>
                        <div style={{ position: "absolute", top: 0, left: 0 }}>
                          {chartMaxValue.toLocaleString("en-US", {
                            style: "currency",
                            currency: "SGD",
                          })}
                        </div>
                      </div>
                      <div style={{ height: "220px" }}></div>
                    </div>
                    <div
                      style={{
                        width: "95%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginTop: "25px",
                          borderBottom: "1px solid lightgrey",
                          height: "200px",
                          alignItems: "flex-end",
                        }}
                      >
                        <div
                          style={{
                            height: "200px",
                            borderLeft: "1px solid lightgrey",
                          }}
                        />
                        {chartData.map((dat, index) => (
                          <div
                            key={index}
                            style={{
                              marginLeft: "4%",
                              marginRight: "4%",
                              width: "8%",
                              height: `${
                                (parseFloat(dat.value.replace(/,/g, "")) /
                                  chartMaxValue) *
                                100
                              }%`,
                              background: "rgba(217, 238, 255, 1)",
                              borderRadius: "8px 8px 0px 0px",
                            }}
                          />
                        ))}
                      </div>
                      <div
                        style={{
                          marginTop: "8px",
                          display: "flex",
                          fontSize: 8,
                        }}
                      >
                        {chartData.map((dat, index) => (
                          <div
                            key={index}
                            style={{
                              width: "16%",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ fontWeight: 600, fontSize: 9 }}>
                              {dat.date.charAt(0).toUpperCase() +
                                dat.date.slice(1)}
                            </div>
                            <div>
                              {parseFloat(
                                dat.value.replace(/,/g, "")
                              ).toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div style={{ color: "red" }}>
            Your current Credit Bureau Report is over a month old. To ensure the
            accuracy of your application, please upload a fresh Credit Bureau
            Report.
          </div>
        )
      ) : null}
    </div>
  );
  const SetCompanyKeyman = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        padding: "2.5% 5%",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            marginTop: "80px",
          }}
        >
          Please select the keyman for this loan
        </div>
      </div>
      <div style={{ width: isMobile ? "100%" : "80%", marginTop: "25px" }}>
        <Select
          style={{ width: isMobile ? "100%" : "90%" }}
          value={companyKeyman}
          onChange={(value) => {
            setCompanyKeyman1(value);
          }}
        >
          {keymanChoices.map((value, index) => (
            <Option value={value} key={index}>
              {value}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );

  const [urlClicked, setUrlClicked] = useState(false);
  const iframeRef = useRef(null);
  const accessCode = "your-access-code";

  // Function to inject script into iframe content
  const injectScript = () => {
    console.log("injecting script");
    const iframeDocument = iframeRef.current.contentDocument;
    if (
      iframeDocument &&
      iframeDocument.location.href === "http://localhost:3000/home"
    ) {
      console.log("iframeDocument:", iframeDocument);
      try {
        console.log("DOMContentLoaded event fired");
        const promoCodeElement = iframeDocument.getElementById("promoCode");
        if (promoCodeElement) {
          console.log("promoCode element found:", promoCodeElement);
          promoCodeElement.value = accessCode;
        } else {
          console.log("promoCode element not found");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("iframeDocument not found or URL mismatch");
    }
  };

  // Callback function to execute when the iframe is loaded
  const handleIframeLoad = () => {
    injectScript();
  };

  // Listen for changes in the iframe URL and inject script accordingly
  // useEffect(() => {
  //   if (iframeRef.current) {
  //     const iframeDocument = iframeRef.current.contentDocument;
  //     const handleUrlChange = () => {
  //       if (
  //         iframeDocument.location.href ===
  //         "http://localhost:3000/home"
  //       ) {
  //         injectScript();
  //       }
  //     };
  //     if (iframeDocument) {
  //       iframeDocument.addEventListener("DOMContentLoaded", handleUrlChange);
  //     }
  //     return () => {
  //       if (iframeDocument) {
  //         iframeDocument.removeEventListener(
  //           "DOMContentLoaded",
  //           handleUrlChange
  //         );
  //       }
  //     };
  //   }
  // }, [iframeRef, injectScript]);

  const [isPictureModalOpen, setIsPictureModalOpen] = useState(null);

  const CreditReportPage = (
    <div
      style={{
        width: isMobile ? "80%" : "100%",
        marginLeft: isMobile ? "10%" : "0px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {!urlClicked ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "rgba(0,0,0,0.6)",
              fontWeight: 700,
              fontSize: 20,
              textAlign: "center",
            }}
          >
            Let's fetch your Credit Report
          </div>
          <div
            style={{
              color: "rgba(0,0,0,0.6)",
              fontWeight: 400,
              fontSize: 14,
              textAlign: "center",
            }}
          >
            Smart-Lend provides every users a complementary retrieval of their
            credit report upon applying for a loan on Smart-Lend.
          </div>
          {promoCode ? (
            <div style={{ marginTop: 20, display: "flex" }}>
              <div
                style={{
                  padding: "10px 45px",
                  borderRadius: "8px 0px 0px 8px",
                  border: "1px solid lightgrey",
                }}
              >
                {promoCode}
              </div>
              <div
                onClick={() => {
                  const textToCopy = promoCode; // Replace with the text you want to copy
                  navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                      message.success("Promo code copied to clipboard!");
                    })
                    .catch((err) => {
                      console.error("Failed to copy text: ", err);
                    });
                }}
                style={{
                  background: "#196cb0",
                  color: "white",
                  borderRadius: "0px 8px 8px 0px",
                  padding: "10px 25px",
                  fontWeight: 700,
                  cursor: "pointer",
                }}
              >
                <ContentPasteIcon style={{ color: "white", fontSize: 24 }} />
              </div>
            </div>
          ) : null}
          {!isMobile ? (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                width: "100%",
                gap: 20,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ marginTop: 20, width: isMobile ? "20%" : "16%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 1
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("1")}
                  src={CBS1}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Copy your free CBS Report Code
                </div>
              </div>
              <div style={{ marginTop: 20, width: isMobile ? "20%" : "16%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 2
                </div>
                <img
                  src={CBS2}
                  onClick={() => setIsPictureModalOpen("2")}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Sync with Singpass
                </div>
              </div>
              <div style={{ marginTop: 20, width: isMobile ? "20%" : "16%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 3
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("3")}
                  src={CBS3}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Paste the promo code
                </div>
              </div>
              <div style={{ marginTop: 20, width: isMobile ? "20%" : "16%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 4
                </div>
                <img
                  onClick={() => setIsPictureModalOpen("4")}
                  src={CBS4}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Download your CBS report by clicking{" "}
                  <span style={{ fontWeight: 700 }}>"SAVE"</span>
                </div>
              </div>
              <div style={{ marginTop: 20, width: isMobile ? "20%" : "16%" }}>
                <div style={{ color: "grey", fontWeight: 700, fontSize: 20 }}>
                  Step 5
                </div>
                <img
                  src={CBS5}
                  onClick={() => setIsPictureModalOpen("5")}
                  style={{
                    width: "150px",
                    objectFit: "contain",
                    height: "150px",
                    borderRadius: 10,
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    cursor: "pointer",
                  }}
                />
                <div style={{ marginTop: 10, color: "grey", fontSize: 16 }}>
                  Click next and upload the report
                </div>
              </div>
            </div>
          ) : null}
          <button
            onClick={(e) => {
              e.preventDefault();
              // window.open(
              //   "https://www.creditbureau.com.sg/SmartLend_Financial.html",
              //   "_blank"
              // );
              setSavePdfReminder(true);
            }}
            style={{
              background: "#196cb0",
              color: "white",
              border: "2px solid #196cb0",
              padding: "5px 35px",
              borderRadius: "20px",
              cursor: "pointer",
              marginTop: 20,
            }}
          >
            Get Started
          </button>
        </div>
      ) : (
        <iframe
          ref={iframeRef}
          src="https://www.creditbureau.com.sg/SmartLend_Financial.html"
          width="100%"
          height="100%"
          onLoad={handleIframeLoad}
        ></iframe>
      )}
    </div>
  );

  const Authorization = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "2.5% 5%",
        flexWrap: "wrap",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "23px",
          }}
        >
          Please Review The Loan Application Details Before Confirming
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            fontSize: "18px",
          }}
        >
          Application Information
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          First Name
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.borrower_name || firstName || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Last Name
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.borrower_name || lastName || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Residential Status
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.residential_status || residentialStatus || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Nationality
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.nationality || nationality || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>Email</div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.email || email || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Contact No.
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.contact_no || contactNo || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Address
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.registered_address || registeredAddress || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Business Name
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.registered_business_name || registeredBusinessName || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Country of Incorporation
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.country_of_incorporation || countryOfIncorporation || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registration Date
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.registration_date || registrationDate || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>UEN</div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.uen || UEN || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Registered Business Address
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.registered_business_address ||
            registeredBusinessAddress ||
            "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Company Contact No.
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.company_contact || companyContactNo || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Company Email
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {data[0]?.company_email || companyEmail || "-"}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            fontSize: "18px",
            margin: "0px",
          }}
        >
          Financial Profile
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "50%" }}>
              <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                Your Latest 6 Month Bank Statement
              </div>
            </div>

            <div style={{ width: "80%", marginTop: "25px" }}>
              <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                <Dragger
                  beforeUpload={(file) => {
                    return false;
                  }}
                  fileList={fileList.concat(
                    fileList2,
                    fileList3,
                    fileList4,
                    fileList5,
                    fileList6
                  )}
                  onPreview={async (file) => {
                    // Use window.open to open the file URL in a new tab
                    window.open(file.url);
                  }}
                  multiple
                >
                  <div style={{ padding: "30px 0px" }}>
                    <div>
                      <ArchiveIcon
                        style={{ fontSize: 50, color: "rgb(25, 108, 176)" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgb(100,100,100)",
                        fontWeight: 500,
                        fontSize: 15,
                        width: "70%",
                        marginLeft: "15%",
                      }}
                    >
                      Upload files
                    </div>
                    <div
                      style={{
                        color: "rgb(100,100,100)",
                        fontWeight: 400,
                        fontSize: 10,
                        width: "70%",
                        marginLeft: "15%",
                      }}
                    >
                      You can upload by dragging your files here or you can also
                      click here to prompt and upload.
                    </div>
                  </div>
                  {/* <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button> */}
                </Dragger>
              </div>
            </div>
          </div>

          {/* <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousMonth(new Date(), 1)} Statement
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList2}
                      beforeUpload={(file) => {
                        setFileList2([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList2([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousMonth(new Date(), 2)} Statement
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList3}
                      beforeUpload={(file) => {
                        setFileList3([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList3([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousMonth(new Date(), 3)} Statement
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList4}
                      beforeUpload={(file) => {
                        setFileList4([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList4([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousMonth(new Date(), 4)} Statement
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList5}
                      beforeUpload={(file) => {
                        setFileList5([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList5([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousMonth(new Date(), 5)} Statement
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList6}
                      beforeUpload={(file) => {
                        setFileList6([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList6([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div> */}

          <div style={{ width: "100%" }}>
            <div style={{ width: "50%" }}>
              <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                Your Latest 2 Years Financial Report
              </div>
            </div>
            <div style={{ width: "80%", marginTop: "25px" }}>
              <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                <Dragger
                  beforeUpload={(file) => {
                    return false;
                  }}
                  fileList={fileList7}
                  onChange={handleChange2}
                  onPreview={async (file) => {
                    // Use window.open to open the file URL in a new tab
                    window.open(file.url);
                  }}
                  multiple
                >
                  <div style={{ padding: "30px 0px" }}>
                    <div>
                      <ArchiveIcon
                        style={{ fontSize: 50, color: "rgb(25, 108, 176)" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgb(100,100,100)",
                        fontWeight: 500,
                        fontSize: 15,
                        width: "70%",
                        marginLeft: "15%",
                      }}
                    >
                      Upload files
                    </div>
                    <div
                      style={{
                        color: "rgb(100,100,100)",
                        fontWeight: 400,
                        fontSize: 10,
                        width: "70%",
                        marginLeft: "15%",
                      }}
                    >
                      You can upload by dragging your files here or you can also
                      click here to prompt and upload.
                    </div>
                  </div>
                  {/* <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button> */}
                </Dragger>
              </div>
            </div>
          </div>
          {/* <div style={{ width: "45%", display: "flex" }}>
                <div style={{ width: "50%", marginTop: "30px" }}>
                  <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                    {getPreviousYear(2)} Financial Report
                  </div>
                </div>
                <div style={{ width: "50%", marginTop: "25px" }}>
                  <div>
                    <Upload
                      fileList={fileList8}
                      beforeUpload={(file) => {
                        setFileList8([
                          {
                            uid: "-1",
                            name: file.name,
                            status: "done",
                            url: URL.createObjectURL(file),
                          },
                        ]);
                        return false;
                      }}
                      onPreview={async (file) => {
                        // Use window.open to open the file URL in a new tab
                        window.open(file.url);
                      }}
                      onRemove={(file) => {
                        setFileList8([]); // This assumes you're only handling one file at a time.
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginBottom: "5px",
                        }}
                        icon={<UploadOutlined />}
                        // onClick={(event) => handleUpload( event)}
                      >
                        Upload
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div> */}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            fontSize: "18px",
          }}
        >
          Credit Report
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
              <Upload
                fileList={fileList9}
                beforeUpload={(file) => {
                  setFileList9([
                    {
                      uid: "-1",
                      name: file.name,
                      status: "done",
                      url: URL.createObjectURL(file),
                      actualFile: file,
                    },
                  ]);
                  setCreditReportFile(file);
                  return false;
                }}
                onPreview={async (file) => {
                  // Use window.open to open the file URL in a new tab
                  window.open(file.url);
                }}
                onRemove={(file) => {
                  setFileList9([]); // This assumes you're only handling one file at a time.
                  setCreditReportFile(null);
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    marginBottom: "5px",
                  }}
                  icon={<UploadOutlined />}
                  // onClick={(event) => handleUpload( event)}
                >
                  Upload
                </Button>
              </Upload>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.5)",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Related Personal Information
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div style={{ width: "50%", marginTop: "20px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Phone No.
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_mobile_no ||
                    shareHolderContactNo ||
                    "-"}
                </div>
              </div>
              <div style={{ width: "50%", marginTop: "20px" }}>
                <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
                  Director Shareholder Email
                </div>
                <div
                  style={{
                    color: "rgba(0,0,0,0.8)",
                    fontWeight: "500",
                    marginTop: "5px",
                  }}
                >
                  {directorData[0]?.corppass_email || shareHolderEmail || "-"}
                </div>
              </div>
            </div>
          </div> */}
      <div style={{ width: "100%", marginTop: "50px" }}>
        <div
          style={{
            color: "rgba(0,0,0,0.5)",
            fontWeight: "700",
            fontSize: "18px",
          }}
        >
          Loan Request
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Loan Category
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {loanType === "simple" ? "Unsecured Business Term Loan" : "-" || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Requesting Loan Amount
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {requestingLoanAmount.toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }) || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Expecting Interest Rate
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {expectingInterestRate === "2"
            ? "1.1% - 2%"
            : expectingInterestRate === "3"
            ? "2.1% 3%"
            : expectingInterestRate === "5"
            ? "3.1% - 5%"
            : "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Loan Period
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {loanPeriod + " Months" || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Total Amount Payable
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {parseFloat(request_principal).toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }) || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Expected Monthly Installment
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {parseFloat(request_monthly_installment).toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }) || "-"}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Expected Processing Fee
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {parseFloat(adminFee * requestingLoanAmount).toLocaleString("en-US", {
            style: "currency",
            currency: "SGD",
          }) || "-"}
          {(parseFloat(adminFee) * 100).toFixed(0) === "3"
            ? " (1% - 3%)"
            : (parseFloat(adminFee) * 100).toFixed(0) === "5"
            ? " (3.1% - 5%)"
            : (parseFloat(adminFee) * 100).toFixed(0) === "7"
            ? " (5.1% - 7%)"
            : ""}
        </div>
      </div>
      <div style={{ width: "50%", marginTop: "20px" }}>
        <div style={{ color: "rgba(0,0,0,0.5)", fontWeight: "700" }}>
          Expected Disbursed Amount
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "500",
            marginTop: "5px",
          }}
        >
          {parseFloat(requestingLoanAmount * (1 - adminFee)).toLocaleString(
            "en-US",
            {
              style: "currency",
              currency: "SGD",
            }
          ) || "-"}
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflowX: "none",
        overflowY: "none",
        position: "relative",
      }}
    >
      <Modal
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                window.open(
                  "https://www.creditbureau.com.sg/SmartLend_Financial.html",
                  "_blank"
                );
                setSavePdfReminder(false);
                handleNextTab(activeTab);
              }}
            >
              Get started
            </Button>
          </div>,
        ]}
        open={savePdfReminder}
        // onOk={handleOk}
        onCancel={() => setSavePdfReminder(false)}
      >
        <img
          style={{
            height: "50vh",
            borderRadius: 10,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            marginTop: 15,
            objectFit: "contain",
            width: "100%",
          }}
          src={CBS4}
        />
        <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
          Download your CBS Report by Clicking "SAVE"
        </div>
        <div style={{ fontWeight: 400, fontSize: 14, marginTop: 10 }}>
          To proceed, please save your Credit Bureau Report as a PDF and upload
          it in the next step.
        </div>
        <div style={{ fontWeight: 400, fontSize: 14, marginTop: 7 }}>
          Important: Do not click on [Print] to save the report; ensure you use
          the "Save " option instead. This will help us process your application
          smoothly and efficiently. Thank you!
        </div>
      </Modal>
      <Modal
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                if (corporateStructure) {
                  // console.log("test");
                  setCoporateNotificationSubmit(true);
                } else {
                  handleSubmit(companyContactNo, companyEmail);
                }
              }}
            >
              Submit
            </Button>
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        Please click the submit button to confirm your application
      </Modal>
      <Modal
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setCoporateNotificationSubmit(false);
                handleSubmit(companyContactNo, companyEmail);
              }}
            >
              Yes
            </Button>
            <Button
              type="default"
              onClick={() => {
                setCoporateNotificationSubmit(false);
              }}
            >
              Cancel
            </Button>
          </div>,
        ]}
        open={corporateNotificationSubmit}
        // onOk={handleOk}
        onCancel={() => {
          setCoporateNotificationSubmit(false);
        }}
      >
        <div style={{ paddingRight: "15px" }}>
          We are pleased to inform you that your financial profile seems to be
          eligible for loans with much better rates from other financial
          institutions, would you like us to proceed to assist in sending your
          application to other financial institutions as well? This will also
          provide you with a higher rate of acceptance for the financials you
          are seeking.
        </div>
      </Modal>
      <Modal
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setCoporateNotificationSubmit(false);
                handleSubmit(companyContactNo, companyEmail);
              }}
            >
              Proceed Submission
            </Button>
            <Button
              type="default"
              onClick={() => {
                setCoporateNotificationSubmit(false);
              }}
            >
              Cancel
            </Button>
          </div>,
        ]}
        open={corporateNotificationSubmit}
        // onOk={handleOk}
        onCancel={() => {
          setCoporateNotificationSubmit(false);
        }}
      >
        Take note that due to the nature of your business's shareholders, your
        loan will subject to a manual review.
      </Modal>
      <Modal
        footer={null}
        open={isPictureModalOpen === null ? false : true}
        onCancel={() => {
          setIsPictureModalOpen(null);
        }}
      >
        {isPictureModalOpen === "1" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS1}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Copy your free CBS Report Promo Code
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Click on the copy icon located beside your free promo code and the
              promo code will automatically be copied into your clipboard.
            </div>
          </div>
        ) : isPictureModalOpen === "2" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS2}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Sync with Singpass
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Follow the steps to provide authorization with your singpass
              account to allow for CBS report retreival.
            </div>
          </div>
        ) : isPictureModalOpen === "3" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS3}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Paste the Promo Code in the field
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Paste the Promo Code that was provided to you earlier in the
              access code field to retreive your free CBS report.
            </div>
          </div>
        ) : isPictureModalOpen === "4" ? (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS4}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Download your CBS Report by Clicking "SAVE"
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Just hit the save button that is highlighted in the green box in
              the image above to download your CBS Report and you can then close
              this tab once the download has completed.
            </div>
          </div>
        ) : (
          <div>
            <img
              style={{
                width: "40vh",
                height: "40vh",
                borderRadius: 10,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                marginTop: 15,
              }}
              src={CBS5}
            />
            <div style={{ fontWeight: 700, fontSize: 20, marginTop: 20 }}>
              Click next and upload the report
            </div>
            <div style={{ fontWeight: 300, fontSize: 14, marginTop: 10 }}>
              Click next at the bottom right of the screen and you'll be brought
              to the above page where you can upload the CBS Report to complete
              your application.
            </div>
          </div>
        )}
      </Modal>
      <div
        style={{
          display: isMobile ? "none" : "block",
          width: "14%",
          background: "white",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          padding: "2%",
        }}
      >
        <div style={{ color: "#0a3659", fontWeight: "700", fontSize: "30px" }}>
          SmartLend
        </div>
        <div
          style={{
            marginTop: "30px",
            fontSize: activeTab === "Business Particulars" ? "20px" : "18px",
            color:
              activeTab === "Business Particulars"
                ? "#196cb0"
                : "rgba(0,0,0,0.6)",
            fontWeight: "600",
            transition: "all 0.2s ease-in-out",
          }}
        >
          Business Particulars
        </div>
        <div
          // onClick={() => setActiveTab("Application Information")}
          style={{
            marginTop: "10px",
            fontSize: activeTab === "Application Information" ? "16px" : "14px",
            color:
              activeTab === "Application Information"
                ? "#196cb0"
                : "rgba(0,0,0,0.6)",
            fontWeight: "400",
            transition: "all 0.2s ease-in-out",
            // cursor: "pointer",
          }}
        >
          • Application Information
        </div>
        {!corporateStructure ? (
          <div
            // onClick={() => setActiveTab("Company Keyman")}
            style={{
              marginTop: "10px",
              fontSize: activeTab === "Company Keyman" ? "16px" : "14px",
              color:
                activeTab === "Company Keyman" ? "#196cb0" : "rgba(0,0,0,0.6)",
              fontWeight: "400",
              transition: "all 0.2s ease-in-out",
              // cursor: "pointer",
            }}
          >
            • Company Keyman
          </div>
        ) : null}
        <div
          // onClick={() => setActiveTab("Financial Profile")}
          style={{
            marginTop: "10px",
            fontSize: activeTab === "Financial Profile" ? "16px" : "14px",
            color:
              activeTab === "Financial Profile" ? "#196cb0" : "rgba(0,0,0,0.6)",
            fontWeight: "400",
            transition: "all 0.2s ease-in-out",
            // cursor: "pointer",
          }}
        >
          • Financial Profle
        </div>
        <div
          // onClick={() => setActiveTab("Credit Report")}
          style={{
            marginTop: "10px",
            fontSize: activeTab === "Credit Report" ? "16px" : "14px",
            color:
              activeTab === "Credit Report" ? "#196cb0" : "rgba(0,0,0,0.6)",
            fontWeight: "400",
            transition: "all 0.2s ease-in-out",
            // cursor: "pointer",
          }}
        >
          • Credit Report
        </div>
        <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
        <div
          // onClick={() => setActiveTab("Authorization")}
          style={{
            fontSize: activeTab === "Authorization" ? "20px" : "18px",
            color:
              activeTab === "Authorization" ? "#196cb0" : "rgba(0,0,0,0.6)",
            fontWeight: "600",
            transition: "all 0.2s ease-in-out",
            // cursor: "pointer",
          }}
        >
          Authorization
        </div>
        <hr style={{ margin: "15px 0px 10px 0px", width: "80%" }} />
      </div>

      <div
        style={{
          width: "100%",
          background: "#d9eeff",
          overflowY: "hidden",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <div style={{ position: "fixed", top: 10, right: 10 }}>
          <CloseIcon
            style={{
              fontSize: "50px",
              color: "rgba(0,0,0,0.5)",
              cursor: "pointer",
            }}
          />
        </div> */}
        <div
          style={{
            width: "80%",
            height: "90%",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              background: "white",
              height: "5.5%",
              width: "100%",
              borderRadius: "15px 15px 0px 0px",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {activeTab === "Business Particulars" ? (
                <FeedIcon style={{ marginRight: "10px", color: "#196cb0" }} />
              ) : activeTab === "Application Information" ? (
                <FeedIcon style={{ marginRight: "10px", color: "#196cb0" }} />
              ) : activeTab === "Financial Profile" ? (
                <RequestQuoteIcon
                  style={{ marginRight: "10px", color: "#196cb0" }}
                />
              ) : activeTab === "Related Personal Information" ? (
                <AccountBoxIcon
                  style={{ marginRight: "10px", color: "#196cb0" }}
                />
              ) : (
                // ) : activeTab === "Tax Declaration" ? (
                //   <AccountBalanceIcon
                //     style={{ marginRight: "10px", color: "#196cb0" }}
                //   />
                // ) : activeTab === "Loan Request" ? (
                //   <RequestQuoteIcon
                //     style={{ marginRight: "10px", color: "#196cb0" }}
                //   />
                <TaskAltIcon
                  style={{ marginRight: "10px", color: "#196cb0" }}
                />
              )}
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "0",
                }}
              >
                {activeTab}
              </p>
            </div>
          </div>
          <div
            style={{
              background: "white",
              width: "100%",
              height: "90%",
              overflowY: "auto",
            }}
          >
            {activeTab === "Application Information"
              ? ApplicationInformation
              : activeTab === "Financial Profile"
              ? FinancialProfile
              : activeTab === "Authorization"
              ? Authorization
              : activeTab === "Credit Report"
              ? CreditReportPage
              : activeTab === "Company Keyman"
              ? SetCompanyKeyman
              : CreditReportUpload}
          </div>
          <div
            style={{
              background: "white",
              height: "5.5%",
              width: "100%",
              borderRadius: "0px 0px 15px 15px",
              borderTop: "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {activeTab !== "Application Information" && (
              <div
                onClick={() => handleBackTab(activeTab)}
                style={{
                  background: "white",
                  color: "#196cb0",
                  border: "2px solid #196cb0",
                  padding: "5px 30px",
                  borderRadius: "20px",
                  marginRight: "10px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
              >
                Back
              </div>
            )}
            {activeTab === "Application Information" &&
            companyContactNo &&
            companyEmail ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
            {activeTab === "Company Keyman" && companyKeyman ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
            {activeTab === "Financial Profile" &&
            fileList.length > 0 &&
            fileList2.length > 0 &&
            fileList3.length > 0 &&
            fileList4.length > 0 &&
            fileList5.length > 0 &&
            fileList6.length > 0 &&
            (fileList7.length > 0 || noFinancialReport) ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
            {activeTab === "Credit Report" ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
            {activeTab === "Credit Report Upload" && fileList9.length > 0 ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
            {activeTab === "Authorization" && fileList9.length > 0 ? (
              <div
                onClick={() =>
                  activeTab === "Authorization"
                    ? showModal()
                    : handleNextTab(activeTab)
                }
                style={{
                  background: "#196cb0",
                  color: "white",
                  border: "2px solid #196cb0",
                  padding: "5px 35px",
                  borderRadius: "20px",
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                {activeTab === "Authorization" ? "Submit" : "Next"}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 500,
            background: "rgba(256,256,256,0.65)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{ color: "#1677ff" }}
            size={80}
            thickness={5} // Adjust thickness as needed
          />
        </div>
      ) : null}
    </div>
  );
};
export default ModalApplication;
