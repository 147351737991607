import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import React, { useState, useEffect } from "react";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import TinyLineChart from "../../components/TinyLineChart";
import HalfPieChart from "../../components/HalfPieChart";
import { Modal, Button } from "antd";
import Cookies from "js-cookie";
import { format } from "date-fns";
import warn_ico from "../../assets/img/exclamation.png";
import { useHistory } from "react-router-dom";
import TableOfferedLoan from "../../components/TableOfferedLoan";
import LoanDetails from "./loan-details";
import applyLoan from "../../assets/img/apply-loan.png";
import { message } from "antd";
import dayjs from "dayjs";
import CountdownWithHover from "../../components/CountDownWithHover";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import * as Icons from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContainerActivity2 = ({ noData }) => (
  <div
    style={{
      width: "100%",
      textAlign: "left",
      marginBottom: "12.5px",
      border: "1px solid none",
      borderRadius: "20px",
      backgroundColor: "#FFFFFFB3",
      height: "60px",
    }}
  >
    <p
      style={{
        fontFamily: "Poppins, sans-serif",
        fontWeight: "600",
        fontSize: "18px",
        color: "#142C44CC",
        textAlign: "center",
        paddingTop: "15px",
      }}
    >
      {noData}
    </p>
  </div>
);

const RequestedLoans = () => {
  const iconLibrary = Icons;
  const iconNames = Object.keys(iconLibrary);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [sortBy, setSortBy] = useState("default");
  const [data, setData] = useState([]);
  const [dataActivityLog, setActivityLog] = useState([]);
  const [dataClick, setDataClick] = useState([]);
  const [offeredLoanDetail, setOfferedLoanDetail] = useState(null);
  const history = useHistory();
  const [lenderId, setLenderId] = useState(null);
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [amount, setAmount] = useState(null);
  const [admin_fee, setAdminFee] = useState(null);
  const [interest_rate, setInterestRate] = useState(null);
  const [period, setPeriod] = useState(null);
  const [monthly_installment, setMonthlyInstallment] = useState(null);
  const [revenue, setRevenue] = useState(null);
  const [add_document, setAddDocument] = useState(null);
  const [express, setExpress] = useState(null);
  const [processingFee, setProcessingFee] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [total_admin_fee, setTotalAdminFee] = useState(null);
  const [statusMessage, setStatusMessage] = useState({
    message: "",
    color: "",
  });
  const [hovered3, setHovered3] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [footerVisible, setFooterVisible] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  const [loanAuthorizationModal, setLoanAuthorizationModal] = useState(false);
  const [loanAuthorizationList, setLoanAuthorizationList] = useState([]);
  const [specialLoans, setSpecialLoans] = useState([])


  const checkIfSpecialLoanEligible = (item) => {
    const singpassData = JSON.parse(item.user.singpass_data)
    const cbsData = JSON.parse(item.user.credit_report_data)
    console.log(singpassData, cbsData)
    const cbsRating = cbsData.RiskGrade;
    const directorAge = singpassData.person.dob.value
    const companyRegistrationDate = singpassData.entity["basic-profile"]["registration-date"].value


  }
  const location = useLocation();

  const getAuhtorizationList = async (loan_id) => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/getAuthorization",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify({ loan_id: loan_id }),
        }
      );
      const json = await response.json();
      console.log(json.loan_authorizations);
      if (json.loan_authorizations.length > 0) {
        setLoanAuthorizationModal(true);
        setLoanAuthorizationList(json.loan_authorizations);
        const { pathname } = location;
        const newUrl = pathname + location.hash;
        history.replace(newUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authorizationRequired = searchParams.get("authorizationrequired");
    const loanId = searchParams.get("loanid");

    if (authorizationRequired && loanId) {
      getAuhtorizationList(loanId);
    }
  }, [location.search]);

  const handleApplyLoan = (event) => {
    event.preventDefault();
    history.push("/b-apply-loan");
  };

  const Container = ({
    icon,
    title,
    subtitle,
    label,
    description,
    label1,
    label2,
    description1,
    description2,
    label3,
    description3,
    label4,
    description4,
    index,
    loan_id,
    open_modal,
    cancel_modal,
    onClickModal,
    createdAt,
    item,
  }) => {
    const shouldAddMarginTop = index > 3;
    // const description3 = ;
    const [hovered, setHovered] = useState(false);

    return (
      <>
        <div
          style={{
            width: screenWidth > 1200 ? "23.5%" : "calc(50% - 10px)",
            textAlign: "center",
            border: "1px solid white",
            borderRadius: "10px",
            backgroundColor:
              new Date(item.expired_date) > new Date()
                ? !item.make_hidden
                  ? "rgba(256,256,256,0.85)"
                  : "rgba(256,256,256,0.3)"
                : "rgba(256,256,256,0.3)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeigh: screenWidth > 1200 ? "47vh" : "auto",
            padding: "15px",
            position: "relative",
            transition: "0.4s all ease-in-out",
          }}
        >
          <div>
            {title || subtitle ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <img src={icon} alt={title} style={{ width: "55px", height: "55px", borderRadius: "1000px", border: "2px solid #f0f0f0" }} /> */}

                <div style={{}}>
                  <h3
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      margin: "0px",
                    }}
                  >
                    {title}
                  </h3>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "16px",
                      color: "rgba(0,0,0,0.5)",
                      textAlign: "left",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    {subtitle}
                  </p>
                </div>
              </div>
            ) : null}
            <div
              style={{
                textAlign: "left",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%" }}>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "600",
                      fontSize: "11px",
                      color: "rgba(0,0,0,0.4)",
                      textAlign: "left",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    {label}
                  </p>
                  <p
                    style={{
                      fontFamily: "Manrope, sans-serif",
                      fontWeight: "800",
                      fontSize: "16px",
                      color: "rgba(0,0,0,0.5)",
                      textAlign: "left",
                      margin: "0px 0px 10px 0px",
                    }}
                  >
                    {description}
                  </p>
                </div>

                {/* <div style={{ width: "50%", marginLeft: "11px" }}>
                <CountdownWithHover
                  targetDate={targetDate}
                  renderer={renderer}
                />
              </div> */}
              </div>

              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px 0px 0px 0px",
                }}
              >
                {label1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description1}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description4}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label2}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description2}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "10px 0px 0px 0px",
                }}
              >
                {label3}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 10px 0px",
                }}
              >
                {description3 === "Pending" ? "No offers" : description3}
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "600",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "left",
                  margin: "0px",
                }}
              >
                Created At
              </p>
              <p
                style={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "800",
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.5)",
                  textAlign: "left",
                  margin: "0px 0px 20px 0px",
                }}
              >
                {formatDate(createdAt)}
              </p>
            </div>
          </div>

          {item.authorization_completed === false &&
          item.authorization_required === 1 ? (
            <div>
              <button
                className="all_button"
                style={{
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "6px 0px",
                  fontSize: "12px",
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  color: hovered ? "#ffffff" : "#115CA6",
                  background: hovered ? "#115CA6" : "none",
                  outline: "1px solid lightgrey",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  getAuhtorizationList(loan_id);
                }}
              >
                Requires Auth
              </button>
            </div>
          ) : null}
          {description3 === "Offered" ||
          description3 === "Pending Acceptance" ? (
            <>
              {screenWidth > 1200 ? (
                <div>
                  <button
                    className="all_button"
                    style={{
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      color: hovered ? "#ffffff" : "#115CA6",
                      background: hovered ? "#115CA6" : "none",
                      outline: "1px solid lightgrey",
                    }}
                    onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
                    onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
                    onClick={(event) => {
                      checkIfSpecialLoanEligible(item);
                      showModal(loan_id, event);
                    }}
                  >
                    View Offered Loan
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    className="all_button"
                    style={{
                      width: "100%",
                      border: "none",
                      borderRadius: "50px",
                      padding: "6px 0px",
                      fontSize: "12px",
                      margin: "10px 0px 0px 0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      color: hovered ? "#ffffff" : "#115CA6",
                      background: hovered ? "#115CA6" : "none",
                      outline: "1px solid lightgrey",
                    }}
                    onMouseEnter={() => setHovered(true)} // set the state hovered to true  when mouse enter
                    onMouseLeave={() => setHovered(false)} // set the state hovered to false  when mouse leave
                    onClick={(event) => showModal(loan_id, event)}
                  >
                    View Offered Loan
                  </button>
                </div>
              )}
            </>
          ) : null}
          {new Date(item.expired_date) > new Date() ? (
            !item.make_hidden ? (
              <div
                onClick={() => {
                  hideLoanRequest(item.loan_id);
                  const updatingLoanData = [...data];
                  const loanIndex = data.findIndex(
                    (lo) => lo.loan_id === item.loan_id
                  );
                  if (loanIndex > -1) {
                    updatingLoanData[loanIndex].make_hidden = true;
                  }
                  setData(updatingLoanData);
                }}
                className="all_button"
                style={{
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "6px 0px",
                  fontSize: "12px",
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  background: "#115CA6",
                  outline: "1px solid lightgrey",
                  cursor: "pointer",
                }}
              >
                Withdraw Loan Request{" "}
              </div>
            ) : (
              <div
                onClick={() => {
                  unhideLoanRequest(item.loan_id);
                  const updatingLoanData = [...data];
                  const loanIndex = data.findIndex(
                    (lo) => lo.loan_id === item.loan_id
                  );
                  if (loanIndex > -1) {
                    updatingLoanData[loanIndex].make_hidden = false;
                  }
                  setData(updatingLoanData);
                }}
                className="all_button"
                style={{
                  width: "100%",
                  border: "none",
                  borderRadius: "50px",
                  padding: "6px 0px",
                  fontSize: "12px",
                  margin: "10px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#ffffff",
                  background: "#115CA6",
                  outline: "1px solid lightgrey",
                  cursor: "pointer",
                }}
              >
                Push To Market{" "}
              </div>
            )
          ) : null}
        </div>
      </>
    );
  };

  // const Container4 = ({
  //   icon,
  //   title,
  //   subtitle,
  //   label,
  //   description,
  //   label1,
  //   label2,
  //   description1,
  //   description2,
  //   label3,
  //   description3,
  //   label4,
  //   description4,
  //   index,
  //   loan_id,
  //   open_modal,
  //   cancel_modal,
  //   onClickModal,
  //   marginTop,
  // }) => {
  //   return (
  //     <>
  //       <div
  //         style={{
  //           width: "23.5%",
  //           textAlign: "center",
  //           marginRight: "0px",
  //           marginLeft: "15px",
  //           marginTop: marginTop, // add this line

  //           border: "1px solid white",
  //           borderRadius: "20px",
  //           backgroundColor: "#FFFFFFB3",
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //           alignItems: "center",
  //         }}
  //       >
  //         <img
  //           src={applyLoan}
  //           alt="Icon"
  //           style={{ width: "25%", height: "auto", margin: "20px 0" }}
  //         />
  //         <button
  //           style={{
  //             fontFamily: "Poppins, sans-serif",
  //             fontWeight: "500",
  //             fontSize: "16px",
  //             marginBottom: "4px",
  //             borderRadius: "20px",
  //             background: hovered3
  //               ? "linear-gradient(90deg, rgba(138, 156, 197, 0.8) 0%, rgba(21,87,169,1) 100%)"
  //               : "transparent",
  //             border: hovered3 ? "1px solid none" : "1px solid grey",
  //             padding: "5px 20px",
  //             color: hovered3 ? "white" : " grey",
  //           }}
  //           onMouseEnter={() => setHovered3(true)} // set the state hovered to true  when mouse enter
  //           onMouseLeave={() => setHovered3(false)} // set the state hovered to false  when mouse leave
  //           onClick={(event) => handleApplyLoan(event)}
  //         >
  //           Apply Loan
  //         </button>
  //       </div>
  //     </>
  //   );
  // };

  const ContainerTransaction = ({ title, label }) => (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "15px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left", marginLeft: "0px" }}>
        <h3
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "600",
            fontSize: "11px",
            color: "rgba(0,0,0,0.4)",
            margin: "0px",
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "800",
            fontSize: "18px",
            textAlign: "left",
            color: "rgba(20, 44, 68, 0.8)",
            margin: "0px",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );

  const handleContainerClick = async (record) => {
    console.log("record value", record);
    const loan_id = record;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoanDetail?loan_id=${loan_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const loanDetail = json["Requested Loan Details"][0];
      setDataClick(json["Requested Loan Details"]);
      setCategory(loanDetail.category);
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }
  };

  const [userDetails, setUserDetails] = useState(null);

  const getUserDetails = async () => {
    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/profile/getUserDetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        setUserDetails(json.User);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const [lenderProfileData, setLenderProfileData] = useState(null);

  const handleGetLenderProfile = async (lender_id) => {
    try {
      const response1 = await fetch(
        "https://www.smart-lend.com/api/user/getLenderProfileDesign",
        {
          method: "POST",
          body: JSON.stringify({ user_id: lender_id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );

      const json = await response1.json();
      if (json.design !== null) {
        setLenderProfileData(JSON.parse(json.design));
      } else {
        setLenderProfileData(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [bankStatement, setBankStatement] = useState(null);
  const handleRowClick = async (loan_id, offer_id, status) => {
    console.log("loan_id", loan_id);
    console.log("offer_id", offer_id);
    console.log("status", status);

    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/viewOfferedLoanDetail?loan_id=${loan_id}&offer_id=${offer_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();
      const loanDetail = json["Offered Loans Detail"][0];
      setOfferedLoanDetail(json["Offered Loans Detail"]);
      handleGetLenderProfile(json["Offered Loans Detail"][0].lender_id);
      setLenderId(loanDetail.lender_id); // Set the lender_id in the state
      setType(loanDetail.type); // Set the type in the state
      setPrincipal(loanDetail.offer_principal); // Set the principal
      setBankStatement(loanDetail.bank_statement);
      setAmount(loanDetail.offer_amount); // Set the amount in the
      setAdminFee(loanDetail.admin_fee); // Set the admin  fee
      setInterestRate(loanDetail.offer_interest_rate); // Set the interest rate
      setPeriod(loanDetail.offer_period); // Set the period
      setMonthlyInstallment(loanDetail.offer_monthly_installment);
      setRevenue(loanDetail.offer_revenue);
      setExpress(loanDetail.express);
      setProcessingFee(loanDetail.processing_fee);
      setTotalAdminFee(loanDetail.total_admin_fee);
      setAddDocument(loanDetail.add_document);
      setSelectedOfferId(offer_id);
      setSelectedStatus(status);
      setFooterVisible(true);
    } catch (error) {
      console.error("Error retrieving Offered Loans Detail:", error);
    }
  };

  const [acceptingOfferLoanId, setAcceptingLoanOfferLoanId] = useState(null);
  const [hideLoanModal, setHideLoanModal] = useState(false);

  const hideLoanRequest = async (loan_id) => {
    let item = { loan_id: loan_id };
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/hideLoanRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.error(error);
    }
  };

  const unhideLoanRequest = async (loan_id) => {
    let item = { loan_id: loan_id };
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/unhideLoanRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAcceptOffer = async (loan_id) => {
    console.log(loan_id);
    const offer_id = selectedOfferId;

    try {
      const item = {
        offer_id: offer_id,
        loan_id: loan_id,
        lender_id: lenderId,
        type: type,
        express: express,
        category: "business",
        principal: principal,
        amount: amount,
        admin_fee: admin_fee,
        total_admin_fee: total_admin_fee,
        interest_rate: interest_rate,
        period: period,
        monthly_installment: monthly_installment,
        revenue: revenue,
        processing_fee: processingFee,
      };

      console.log(item);

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/acceptLoan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();
      console.log(json);

      if (response.status === 200) {
        // Here, when the response status is 200, close the modal
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));

        // Display the message from the Ant Design library
        message.success(json.message);
        fetchData(); // Fetch the updated data
        history.push("/b-accepted-loans");

        try {
          let item2 = {
            user_id: lenderId,
            channel: "system",
            subject: "Accept offer",
            message: "Borrower had accept your offer for loan ${}",
          };
          const response = await fetch(
            "https://www.smart-lend.com/api/user/notification/sendNotification",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify(item2),
            }
          );
          const data = await response.json();
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }

        try {
          const response = await fetch(
            `https://www.smart-lend.com/api/profile/createActivityLog`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
              body: JSON.stringify({
                user_id: lenderId,
                user_name: offeredLoanDetail?.[0]?.lender_name,
                user_account_type: "lender",
                description: `${userDetails.entity_name} has accepted your loan offer for ${loan_id}`,
              }),
            }
          );
          const json = await response.json();

          console.log(json);
          console.log("Offer created:", data);
        } catch (error) {
          console.error("Error creating offer:", error);
        }
      } else if (response.status === 400) {
        setStatusMessage({
          message: "You have already accepted this loan.",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error", error);
      if (error) {
        setStatusMessage({
          message: "Please click the table row.",
          color: "red",
        });
      }
    }
  };
  const [rejectModal, setRejectModal] = useState(false);
  const [rejectingOffer, setRejectingOffer] = useState(null);
  const [rejectingReason, setRejectingReason] = useState(0);

  const handleRejectOffer = async (loan_id) => {
    console.log("value offer id", selectedOfferId);
    setRejectModal(false);
    const offer_id = selectedOfferId;
    try {
      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/rejectLoan?offer_id=${offer_id}&reason_id=${rejectingReason}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      const json = await response.json();

      try {
        const response = await fetch(
          `https://www.smart-lend.com/api/profile/createActivityLog`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Cookies.get("token"),
            },
            body: JSON.stringify({
              user_id: lenderId,
              user_name: offeredLoanDetail?.[0]?.lender_name,
              user_account_type: "lender",
              description: `${userDetails.entity_name} has rejected your loan offer for ${loan_id}`,
            }),
          }
        );
        const json = await response.json();

        console.log(json);
        console.log("Offer created:", data);
      } catch (error) {
        console.error("Error creating offer:", error);
      }

      if (response.status === 200) {
        // Here, when the response status is 200, close the modal
        setModalStates((prevStates) => ({
          ...prevStates,
          [loan_id]: false,
        }));

        // Display the message from the Ant Design library
        message.success(json.message);
        fetchData(); // Fetch the updated data
      } else if (response.status === 400) {
        setStatusMessage({
          message: "You have already rejected this loan.",
          color: "red",
        });
      }
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
      if (error) {
        setStatusMessage({
          message: "Please click the table row.",
          color: "red",
        });
      }
    }
  };

  //   const handleSelectionChange = (selectedBorrowerIds) => {
  //     console.log('Selected loan IDs:', selectedBorrowerIds);
  //     setselectedBorrowerIds(selectedBorrowerIds);
  //   };
  useEffect(() => {
    // To redirect user if not authenticated
    const isAuthenticated = Cookies.get("token");
    const user_account_type = Cookies.get("user_account_type");

    if (!isAuthenticated) {
      history.push("/auth/signin");
    } else if (user_account_type === "lender") {
      history.push("/dashboard");
    }
  }, [history]);

  useEffect(() => {
    const storedDate = Cookies.get("countdownTargetDate");
    if (storedDate) {
      setTargetDate(Number(storedDate));
    } else {
      if (data && data["Pending Loans"] && data["Pending Loans"].length > 0) {
        const loan = data["Pending Loans"][0]; // adjust according to your needs
        const createdAtDate = new Date(loan.created_at);
        createdAtDate.setMonth(createdAtDate.getMonth() + loan.request_period);

        const newTargetDate = createdAtDate.getTime();
        Cookies.set("countdownTargetDate", newTargetDate);
        setTargetDate(newTargetDate);
      }
    }
  }, [data]);

  const timerStyle = {
    color: "grey",
    fontFamily: "Poppins, sans-serif",
    fontWeight: "400",
    fontSize: "12px",
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    let timeLeft = "";

    if (completed) {
      return <span style={timerStyle}>Time's up!</span>;
    } else {
      if (days > 0) {
        timeLeft = `${days}d ${hours}h ${minutes}m`;
      } else if (hours > 0) {
        timeLeft = `${hours}h ${minutes}m ${seconds}s`;
      } else {
        timeLeft = `${minutes}m ${seconds}s`;
      }

      return <span style={timerStyle}>{timeLeft}</span>;
    }
  };

  const fetchData = async () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + Cookies.get("token"));

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      const response = await fetch(
        "https://www.smart-lend.com/api/loan/pendingLoan/viewPendingLoan",
        options
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      // Transform the data into an array of objects that includes the targetDate
      const transformedData = data["Pending Loans"].map((loan) => {
        const createdAt = new Date(loan.created_at);
        const targetDate = createdAt.setMonth(
          createdAt.getMonth() + loan.request_period
        );
        return { ...loan, targetDate };
      });

      setData(data["Pending Loans"]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  const resetLoanDetail = () => {
    setOfferedLoanDetail([]);
  };

  const [modalStates, setModalStates] = useState({});
  const [open, setOpen] = useState(false);
  const showModal = (loanId, event) => {
    event.preventDefault();
    console.log("loanId modal", loanId);
    setModalStates((prevStates) => ({
      ...prevStates,
      [loanId]: true,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExtendLoan = async (loanId, expired_date, event) => {
    event.preventDefault();

    try {
      let item = {
        loan_id: loanId,
        expired_date: expired_date,
      };

      const response = await fetch(
        `https://www.smart-lend.com/api/loan/pendingLoan/extendLoanRequest`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
          body: JSON.stringify(item),
        }
      );
      const json = await response.json();
    } catch (error) {
      console.error("Error retrieving Requested Loan Details:", error);
    }
  };

  const ContainerActivity = ({ icon, title, subtitle }) => (
    <div
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12.5px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
        height: "60px",
      }}
    >
      <div style={{ display: "flex", alignItems: "left", color: "grey" }}>
        <img
          src={icon}
          alt={title}
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "20px",
            margin: "10px 10px 10px 10px",
          }}
        />
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "5px",
          }}
        >
          <h3
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "800",
              fontSize: "18px",
              textAlign: "left",
              color: "rgba(20, 44, 68, 0.8)",
              margin: "0px 0px 0px 0px",
            }}
          >
            {title}
          </h3>
          <p
            style={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "600",
              fontSize: "11px",
              color: "rgba(0,0,0,0.4)",
              textAlign: "left",
              margin: "0px 0px 0px 0px",
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );

  const formatDate = (dateString) => {
    if (!dateString) return "-"; // Return a default value if the date string is not provided

    const date = new Date(dateString); // Create a Date object from the date string
    const formattedDate = format(date, "MMM dd, yyyy"); // Use date-fns to format the date in the desired format

    return formattedDate;
  };

  const ContainerLineChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        borderRadius: "20px",
      }}
    >
      <TinyLineChart title="$12,000" subtitle="Net profit to date" />
    </div>
  );

  const ContainerPieChart = () => (
    <div
      style={{
        width: "100%",
        height: "200px",
        marginRight: "0px",
        color: "grey",
        borderRadius: "20px",
      }}
    >
      <HalfPieChart title="$802,000" subtitle="Forecast collection" />
    </div>
  );

  const [screenWidth, setScreenWidth] = useState(1920);

  const [isMobile, setIsMobile] = useState(
    Cookies.get("ismobile") === "yes" ? true : false
  );

  useEffect(() => {
    if (screenWidth < 1200) {
      Cookies.set("ismobile", "yes");
      setIsMobile(true);
    } else {
      Cookies.set("ismobile", "no");
      setIsMobile(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m="4px 20px 20px 20px">
      <Modal
        width={isMobile ? "100%" : "40vw"}
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={hideLoanModal}
        onCancel={() => {
          setHideLoanModal(false);
          setAcceptingLoanOfferLoanId(null);
        }}
        footer={
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="primary"
              style={{
                background: "rgb(10, 88, 202)",
                borderRadius: "20px",
                padding: "7px 30px",
                border: "none",
                color: "white",
              }}
              onClick={(e) => {
                e.preventDefault();
                hideLoanRequest(acceptingOfferLoanId);
                handleAcceptOffer(acceptingOfferLoanId);
              }}
            >
              <div style={{ margin: 0 }}>Yes</div>
            </button>
            <button
              type="primary"
              style={{
                color: "rgb(10, 88, 202)",
                border: "2px solid rgb(10, 88, 202)",
                borderRadius: "20px",
                padding: "7px 30px",
                color: "white",
              }}
              onClick={(e) => {
                e.preventDefault();
                handleAcceptOffer(acceptingOfferLoanId);
              }}
            >
              <div style={{ margin: 0, color: "rgb(10, 88, 202)" }}> No</div>
            </button>
          </div>
        }
      >
        <Typography
          color={colors.grey[920]}
          variant="h5"
          fontWeight="800"
          fontFamily="Manrope, sans-serif"
          fontSize="18px"
          style={{ marginBottom: "15px" }}
        >
          Withdraw Loan Request
        </Typography>
        <div
          style={{
            color: "#142C44CC",
            fontSize: 14,
            fontWeight: 500,
            width: "90%",
            marginTop: "-10px",
          }}
        >
          We are happy that you have found an offer to your liking, would you
          like to withdraw your loan request as you accept this offer?
        </div>
      </Modal>
      <Modal
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={loanAuthorizationModal}
        onCancel={() => {
          setLoanAuthorizationModal(false);
        }}
        footer={null}
      >
        <div style={{ color: "#142C44CC", fontSize: 20, fontWeight: 800 }}>
          Authorisation | One more step
        </div>
        <div
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 7,
          }}
        >
          For your loan to appear on our lenders screen, you'll need to provide
          authorisation from your shareholders.
        </div>
        {loanAuthorizationList.map((autho, index) => (
          <div
            key={index}
            style={{
              width: "100%",
              padding: "15px",
              borderRadius: 10,
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 30,
            }}
          >
            <div style={{ color: "#142C44CC", fontSize: 14, fontWeight: 800 }}>
              <div>{autho.director_name}</div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontSize: 12,
                  fontWeight: 500,
                  marginBottom: 7,
                }}
              >
                {autho.company_name}
              </div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontSize: 12,
                  fontWeight: 500,
                  marginBottom: 7,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px 0px 0px 8px",
                    padding: "10px 25px",
                    boxShadow: "0px 0px 6px rgba(0,0,0,0.2)",
                  }}
                >
                  {(
                    "https://smart-lend.com/director-authorization?authorisationid=" +
                    autho.id +
                    "&companyname=" +
                    autho.company_name +
                    "&name=" +
                    autho.director_name
                  ).slice(0, 25) + "..."}
                </div>
                <div
                  onClick={() => {
                    const textToCopy =
                      "http://smart-lend.com/director-authorization?authorisationid=" +
                      autho.id +
                      "&companyname=" +
                      autho.company_name +
                      "&name=" +
                      autho.director_name; // Replace with the text you want to copy
                    navigator.clipboard
                      .writeText(textToCopy)
                      .then(() => {
                        message.success("link copied to clipboard!");
                      })
                      .catch((err) => {
                        console.error("Failed to copy text: ", err);
                      });
                  }}
                  style={{
                    background: "#196cb0",
                    color: "white",
                    borderRadius: "0px 8px 8px 0px",
                    padding: "10px 25px",
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  <ContentPasteIcon style={{ color: "white", fontSize: 24 }} />
                </div>
              </div>
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontSize: 12,
                  fontWeight: 500,
                  marginBottom: 7,
                }}
              >
                Copy the above link and send them to the respected individuals
                where they can follow the express steps to provide authorisation
                for this loan.
              </div>
            </div>
            <div>
              {autho.authorized ? (
                <CheckCircleIcon style={{ fontSize: 30, color: "green" }} />
              ) : (
                <CancelIcon style={{ fontSize: 30, color: "red" }} />
              )}
            </div>
          </div>
        ))}
      </Modal>
      <Modal
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        open={rejectModal}
        onCancel={() => {
          setRejectModal(false);
          setRejectingOffer(null);
          setRejectingReason(0);
        }}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 0px 10px 0px",
          }}
        >
          <div>Please provide a reason why you'd like to reject this offer</div>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <select
              value={rejectingReason}
              onChange={(e) => setRejectingReason(e.target.value)}
              style={{ textAlign: "center" }}
            >
              <option value={0}>
                I have a better rate from another lender
              </option>
              <option value={1}>The offer doesnt meet my requirements</option>
              <option value={2}>The rates are too high</option>
              <option value={3}>I no longer need a loan</option>
            </select>
          </div>
          {rejectingReason !== null && rejectingOffer ? (
            <div style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                style={{
                  borderRadius: "20px",
                  padding: "0px 20px",
                }}
                danger
                onClick={() => {
                  handleRejectOffer(rejectingOffer);
                }}
              >
                Reject Offer
              </Button>
            </div>
          ) : null}
        </div>
      </Modal>
      <Box>
        <Box
          height={isMobile ? "77vh" : "88.7vh"}
          borderRadius="10px"
          border="0px solid "
          opacity="0.95"
          style={{
            overflowY: "scroll",
            background:
              "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          }}
        >
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            background="transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box"
            border-radius="20px"
            opacity="0.95"
            style={{ width: "100%", padding: "20px" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ width: "100%" }}
              borderRadius="10px"
            >
              <Typography
                color={colors.grey[920]}
                variant="h5"
                fontWeight="800"
                fontFamily="Manrope, sans-serif"
                fontSize="18px"
              >
                Requested Loans
              </Typography>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "250px",
                }}
              >
                <label
                  htmlFor="sort-by"
                  style={{
                    marginRight: "7px",
                    marginLeft: "10px",
                    color: "#142C44CC",
                    fontWeight: "800",
                    fontSize: "13px",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Sort by:
                </label>
                <div style={{ width: "60%" }}>
                  <select
                    id="sort-by"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    style={{
                      width: "100%",
                      borderRadius: "30px",
                      color: "#0E5AA5",
                      backgroundColor: "#ECF0F5",
                      border: "1px solid #3E536733",
                      fontWeight: "600",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "13px",
                    }}
                  >
                    <option value="loan-amount">Loan Amount</option>
                    <option value="date">Requested Tenure</option>
                  </select>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4" style={{ marginTop: 15 }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "grey",
                  gap: "15px",
                }}
              >
                {data && data.length > 0 ? (
                  data
                    .sort(
                      (a, b) =>
                        a.request_interest_rate - b.request_interest_rate
                    )
                    // .filter((item) => item.status !== "accepted") // filter out items with status "accepted"
                    .map((item, index) => (
                      <Container
                        key={index}
                        // icon="https://cdn.pixabay.com/photo/2018/05/08/21/28/apple-3384010_960_720.png"
                        // title={item.principal_name || "-"}
                        // subtitle={item.express === 1 ? "Express Applicant" : "Approved Borrower"}
                        label="Loan Amount"
                        data={item}
                        description={
                          item.request_amount
                            ? item.request_amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "SGD",
                              })
                            : "-"
                        }
                        label1="Expected Monthly Payables"
                        description1={
                          item.request_monthly_installment
                            ? item.request_monthly_installment.toLocaleString(
                                "en-US",
                                { style: "currency", currency: "SGD" }
                              )
                            : "-"
                        }
                        label2="Period"
                        description2={item.request_period + " Months" || "-"}
                        label3="Loan Status"
                        description3={
                          item.status === "accepted"
                            ? "Pending Acceptance"
                            : item.status
                            ? item.status.charAt(0).toUpperCase() +
                              item.status.slice(1)
                            : "-"
                        }
                        label4="Expected Interest Rate Per Month"
                        description4={
                          item.request_interest_rate === 2
                            ? "1.1% - 2%"
                            : item.request_interest_rate === 3
                            ? "2.1% - 3%"
                            : "3.1% - 5%"
                        }
                        createdAt={item.created_at}
                        index={index} // pass the index prop to each Container instance
                        loan_id={item.loan_id} // pass the loan_id prop to each Container instance
                        open_modal={modalStates[item.loan_id]} // pass the modal state to each Container instance
                        onClickModal={(event) => showModal(item.loan_id, event)} // pass the showModal function to each Container instance
                        cancel_modal={() => handleClose(item.loan_id)} // pass the cancelModal function to each Container instance
                        item={item}
                      />
                    ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "0px 0px",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 15px",
                        background: "rgba(255,255,255,0.7)",
                        borderRadius: "10px",
                        height: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "0.8em",
                        fontWeight: "600",
                        color: "#00000066",
                      }}
                    >
                      You have no loan request
                    </div>
                  </div>
                )}{" "}
                {/* {data && data.length > 0 && (
                  <Container4 marginTop={data.length > 3 ? "10px" : "0px"} />
                )} */}
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <Modal
                      width={isMobile ? "100%" : "50vw"}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        position: "relative",
                      }}
                      open={modalStates[item.loan_id]}
                      onCancel={() => {
                        setModalStates((prevStates) => ({
                          ...prevStates,
                          [item.loan_id]: false,
                        }));
                        setFooterVisible(false);
                        resetLoanDetail();
                        setStatusMessage({ message: "", color: "" });
                        setLenderProfileData(null);
                      }}
                      footer={
                        footerVisible
                          ? [
                              <React.Fragment key="footer-elements">
                                {selectedStatus === "pending" && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "25px",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      style={{
                                        background: "#0DC45C",
                                        borderRadius: "20px",
                                      }}
                                      onClick={() => {
                                        console.log(item);
                                        setAcceptingLoanOfferLoanId(
                                          item.loan_id
                                        );
                                        setHideLoanModal(true);
                                        // handleAcceptOffer(item.loan_id);
                                      }}
                                    >
                                      Accept Offer
                                    </Button>{" "}
                                    <Button
                                      type="primary"
                                      style={{
                                        borderRadius: "20px",
                                      }}
                                      danger
                                      onClick={() => {
                                        setRejectModal(true);
                                        setRejectingOffer(item.loan_id);
                                        // handleRejectOffer(item.loan_id);
                                      }}
                                    >
                                      Reject Offer
                                    </Button>
                                    <Button
                                      type="primary"
                                      style={{
                                        background: "white",
                                        border: "1px solid red",
                                        color: "red",
                                        borderRadius: "20px",
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        console.log(item, offeredLoanDetail);
                                        const loanDetailIndex =
                                          offeredLoanDetail.findIndex(
                                            (loan) =>
                                              loan.loan_id === item.loan_id
                                          );
                                        console.log(loanDetailIndex);
                                        if (loanDetailIndex !== -1) {
                                          const url = `/b-messages?principelname=${offeredLoanDetail[loanDetailIndex].lender_name}&userid=${offeredLoanDetail[loanDetailIndex].lender_id}&offeramount=${offeredLoanDetail[loanDetailIndex].offer_amount}`;
                                          history.push(url);
                                          // console.log(url)
                                        }
                                      }}
                                    >
                                      Negotiate Offer
                                    </Button>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "10px",
                                    color: statusMessage.color,
                                  }}
                                >
                                  <p>{statusMessage.message}</p>
                                </div>
                              </React.Fragment>,
                            ]
                          : null
                      }
                    >
                      <div
                        style={{
                          position: "absolute",
                          background: "white",
                          borderRadius: "10px",
                          padding: "20px",
                          top: 0,
                          right: lenderProfileData
                            ? "calc(100% + 20px)"
                            : "100%",
                          opacity: lenderProfileData ? 1 : 0,
                          width: "400px",
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                          transition: "0.4s all ease-in-out",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "15px",
                          }}
                        >
                          <div>
                            <img
                              style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "50%",
                                background: "lightgrey",
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.05)",
                                objectFit: "cover",
                              }}
                              src={lenderProfileData?.profile?.profile_photo}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                color: "rgb(14, 90, 165)",
                                fontSize: "20px",
                                fontFamily: "Manrope,sans-serif",
                                fontWeight: 800,
                                marginBottom: "5px",
                              }}
                            >
                              {lenderProfileData?.profile?.company_name}
                            </div>
                            {lenderProfileData?.profile?.additionals?.map(
                              (addi, index) => (
                                <div
                                  key={index}
                                  style={{ display: "flex", gap: "10px" }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      color: "rgba(0,0,0,0.6)",
                                      marginTop: "-5px",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {addi?.text}
                                  </div>
                                </div>
                              )
                            )}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                marginLeft: "-5px",
                                marginTop: "5px",
                              }}
                            >
                              {lenderProfileData?.profile?.social?.map(
                                (soci, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      padding: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={iconLibrary[soci?.icon]}
                                      style={{
                                        fontSize: 20,
                                        color: "rgb(14, 90, 165)",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if (
                                          soci.link !== "" &&
                                          soci.link !== null
                                        ) {
                                          window.location.href = soci.link;
                                        }
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <hr
                          style={{
                            width: "90%",
                            marginLeft: "5%",
                            background: "grey",
                            border: "none",
                            outline: "none",
                          }}
                        />
                        {lenderProfileData?.content?.map((cont, index) =>
                          cont.type === "text" ? (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginTop: cont.mt,
                                marginBottom: cont.mb,
                                textDecoration: cont.underline
                                  ? "underline"
                                  : "none",
                                fontWeight: cont.fontWeight === 400 ? 400 : 600,
                                fontSize:
                                  cont.fontWeight === 400 ? "12px" : "16px",
                                border: "none",
                                outline: "none",
                                color: "rgba(0,0,0,0.6)",
                                width: "100%",
                              }}
                            >
                              {cont?.text}
                            </div>
                          ) : cont?.type === "button" ? (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "flex",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (cont.url !== "" && cont.url !== null) {
                                  window.location.href = cont.url;
                                }
                              }}
                            >
                              <div
                                style={{
                                  marginTop: cont.mt,
                                  marginBottom: cont.mb,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  border: "1px solid rgba(0,0,0,0.05)",
                                  outline: "none",
                                  color: "white",
                                  background: "rgb(14, 90, 165)",
                                  padding: "7px 30px",
                                  borderRadius: "30px",
                                }}
                              >
                                {cont?.text}
                              </div>
                            </div>
                          ) : (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                marginTop: cont.mt,
                                marginBottom: cont.mb,
                              }}
                            >
                              <img
                                style={{
                                  objectFit: "cover",
                                  width: "80%",
                                  height: "150px",
                                  border: "none",
                                  outline: "none",
                                }}
                                src={cont?.url}
                              />
                            </div>
                          )
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography
                            color={colors.grey[920]}
                            variant="h5"
                            fontWeight="800"
                            fontFamily="Manrope, sans-serif"
                            fontSize="18px"
                            style={{ marginBottom: "15px" }}
                          >
                            Offered Loan
                          </Typography>
                          <div
                            style={{
                              width: isMobile ? "85vw" : "100%",
                              overflowX: "auto",
                            }}
                          >
                            <TableOfferedLoan
                              loan_id={item.loan_id}
                              offer_id={item.offer_id}
                              add_document={add_document}
                              fetchData={fetchData} // pass the function as a prop
                              onRowClick={(loan_id, offer_id, status) =>
                                handleRowClick(loan_id, offer_id, status)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 16,
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Typography
                            color={colors.grey[920]}
                            variant="h5"
                            fontWeight="800"
                            fontFamily="Manrope, sans-serif"
                            fontSize="18px"
                            style={{ marginBottom: "15px" }}
                          >
                            Partnering Solutions
                          </Typography>
                        </div>
                        <div style={{fontSize: "12px", marginTop: "-15px"}}>
                          This fields only appear when candidates meets the
                          requirements to be eligible to loan with better rates.
                        </div>
                        <div style={{width: "100%", }}>
                        </div>
                      </div> */}
                      <hr />
                      {offeredLoanDetail && offeredLoanDetail.length > 0 ? (
                        <LoanDetails offeredLoanDetail={offeredLoanDetail} />
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ErrorOutlineIcon
                            style={{
                              marginRight: "5px",
                              color: "#142C44CC",
                            }}
                          />
                          <p
                            style={{
                              margin: "0px",
                              color: "#142C44CC",
                              fontWeight: "800",
                              fontFamily: "Manrope, sans-serif",
                              fontSize: "18px",
                            }}
                          >
                            Please click on the table row to display the Loan
                            Details
                          </p>
                        </div>
                      )}
                    </Modal>
                  ))}
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RequestedLoans;
