import React, { useEffect, useState } from "react";
import { Progress, Button, Modal, message, Avatar, Collapse } from "antd";
import cookies from "js-cookie";
import SearchOffOutlinedIcon from "@mui/icons-material/SearchOffOutlined";
import { CircularProgress } from "@mui/material";

const Valuer = () => {
  const [valuerForm, setValuerForm] = useState({
    propertyTypeCode: "hdb",
    postalCode: "",
    addressUnit: "",
    addressNumber: "",
    addressStreet: "",
    projectName: "",
    valuationType: "sale",
  });
  const [retreivedData, setRetreivedData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [postalCodeSearching, setPostalCodeSearching] = useState("");

  const submitValuation = async (postalCode, propertyTypeCode) => {
    setSearching(true);
    try {
      let item = {};
      if (
        valuerForm.propertyTypeCode !== null &&
        valuerForm.propertyTypeCode !== ""
      ) {
        item.propertyTypeCode = valuerForm.propertyTypeCode;
      }

      if (propertyTypeCode !== null && propertyTypeCode !== "") {
        item.propertyTypeCode = propertyTypeCode;
      }

      if (postalCode !== null && postalCode !== "") {
        item.postalCode = postalCode;
      }
      if (valuerForm.addressUnit !== null && valuerForm.addressUnit !== "") {
        item.addressUnit = valuerForm.addressUnit;
      }
      if (
        valuerForm.addressNumber !== null &&
        valuerForm.addressNumber !== ""
      ) {
        item.addressNumber = valuerForm.addressNumber;
      }
      if (
        valuerForm.addressStreet !== null &&
        valuerForm.addressStreet !== ""
      ) {
        item.addressStreet = valuerForm.addressStreet;
      }
      if (valuerForm.projectName !== null && valuerForm.projectName !== "") {
        item.projectName = valuerForm.projectName;
      }
      if (
        valuerForm.valuationType !== null &&
        valuerForm.valuationType !== ""
      ) {
        item.valuationType = valuerForm.valuationType;
      }

      const response = await fetch(
        `https://www.smart-lend.com/api/property/getPropertyValuation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        }
      );

      const json = await response.json();

      if (json.code === 200) {
        console.log("call was succesful");
        message.success("API call was succesful!");
        if (Array.isArray(json.data)) {
          const adjustingData = [...json.data];
          for (const data of adjustingData) {
            data.fullAddress =
              data?.addressUnit +
              " " +
              data?.addressNumber +
              ", " +
              data?.addressStreet +
              ", " +
              data?.postalCode +
              ", " +
              data?.country;
          }
          setRetreivedData(json.data);
        }
        setSearching(false);
      }
    } catch (error) {
      console.error(error);
      setSearching(false);
    }
  };
  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setInputSearch(userInput);

    // Extract the 6-digit postal code
    const postalCodeMatch = userInput.match(/\b\d{6}\b/);
    const postalCode = postalCodeMatch ? postalCodeMatch[0] : null;
    console.log(
      postalCodeMatch,
      postalCode && postalCode !== postalCodeSearching
    );

    if (postalCode && postalCode !== postalCodeSearching) {
      submitValuation(postalCode, valuerForm.propertyTypeCode);
      setPostalCodeSearching(postalCode);
    }
  };

  const cleanedInput = inputSearch
    .replace(postalCodeSearching, "") // Remove postal code from the input
    .replace("Unit", "") // Remove postal code from the input
    .replace("Floor", "") // Remove postal code from the input
    .replace("Block", "") // Remove postal code from the input
    .replace("Blk", "") // Remove postal code from the input
    .replace("unit", "") // Remove postal code from the input
    .replace("floor", "") // Remove postal code from the input
    .replace("block", "") // Remove postal code from the input
    .replace("blk", "") // Remove postal code from the input
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .trim()
    .split(/\s+/);

  const filteredSearch = retreivedData?.filter((address) =>
    cleanedInput.every((term) =>
      address.fullAddress.toLowerCase().includes(term.toLowerCase())
    )
  );
  const capitalizeWords = (str) => {
    if (!str) return ""; // Check if the string exists (handles null or undefined)

    return str
      .split(" ") // Split the string by spaces
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(" "); // Join the words back into a string
  };
  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        width: "calc(100% - 40px)",
        marginLeft: "20px",
        background: "red",
        background:
          "transparent linear-gradient(180deg, #FFFFFFB3 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
        padding: "20px",
        borderRadius: "10px",
        marginTop: "5px",
        gap: "5px",
        color: "black",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
      }}
    >
      <div style={{ width: "40%" }}>
        <div style={{ width: "100%", marginBottom: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
              }}
            >
              Property Valuation
            </div>
          </div>
          <div>This form is specifically for test purposes.</div>
        </div>
        <div style={{ width: "100%" }}>
          <div>General Search:</div>
          <input
            value={inputSearch}
            onChange={(e) => {
              console.log(valuerForm.propertyTypeCode);
              handleInputChange(e, valuerForm.propertyTypeCode);
            }}
            placeholder="Search by postal code"
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "100%",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Property Type:</div>
          <select
            value={valuerForm.propertyTypeCode}
            onChange={(e) => {
              setValuerForm({
                ...valuerForm,
                propertyTypeCode: e.target.value,
              });
              submitValuation(postalCodeSearching, e.target.value);
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "100%",
              padding: "5px 8px",
            }}
          >
            <option value={"hdb"}>HDB</option>
            <option value={"condo"}>Condo</option>
            <option value={"land"}>Landed</option>
            <option value={"office"}>Office</option>
          </select>
        </div>
        {/* 
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Unit:</div>
          <input
            value={valuerForm.addressUnit}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressUnit: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Number:</div>
          <input
            value={valuerForm.addressNumber}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressNumber: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Address Street:</div>
          <input
            value={valuerForm.addressStreet}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, addressStreet: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ width: "100%", marginTop: "10px" }}>
          <div>Postal Code:</div>
          <input
            value={valuerForm.postalCode}
            onChange={(e) => {
              setValuerForm({ ...valuerForm, postalCode: e.target.value });
            }}
            style={{
              borderRadius: "8px",
              border: "1px solid lightgrey",
              width: "350px",
              padding: "5px 8px",
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div
            onClick={() => {
              submitValuation();
            }}
            style={{
              background: "rgb(17, 92, 166)",
              color: "white",
              padding: "7px 30px",
              border: "none",
              marginTop: "10px",
              borderRadius: "8px",
              cursor: "pointer",
            }}
          >
            Submit
          </div>
        </div> */}
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", marginBottom: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                color: "#142C44CC",
                fontSize: "20px",
                fontFamily: "Manrope,sans-serif",
                fontWeight: 800,
              }}
            >
              Search Results
            </div>
          </div>
          <div>Start typing in the search bar to get results here.</div>
        </div>
        {!searching ? (
          filteredSearch && filteredSearch.length > 0 ? (
            filteredSearch.map((addr, index) => (
              <div
                key={index}
                style={{
                  background: "rgba(256,256,256,0.7)",
                  padding: "10px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "#142C44CC",
                  }}
                >
                  Full Address
                </div>
                <div>
                  {"Unit " +
                    addr?.addressUnit +
                    ", BLK " +
                    addr?.addressNumber +
                    ", " +
                    capitalizeWords(addr?.addressStreet) +
                    ", " +
                    addr?.postalCode +
                    ", " +
                    capitalizeWords(addr?.country) +
                    "."}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#142C44CC",
                    marginTop: "10px",
                  }}
                >
                  Valuation Details
                </div>
                <div style={{ display: "flex", gap: "15px", opacity: 0.4 }}>
                  <div>{addr?.sizeSqft + " Sqft"}</div>
                  <div>
                    {addr?.saleValuationPsf?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    }) + " / Sqft"}
                  </div>
                  <div>
                    {addr?.saleValuation?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "SGD",
                    })}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                width: "60%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <SearchOffOutlinedIcon
                style={{ fontSize: "120px", color: "rgba(41, 75, 110, 0.5)" }}
              />
              <div
                style={{
                  color: "rgba(41, 75, 110, 0.5)",
                  fontWeight: 700,
                  fontSize: "24px",
                }}
              >
                No Data Found
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              width: "60%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress
              style={{ color: "rgba(41, 75, 110, 0.5)" }}
              size={150}
              thickness={5} // Adjust thickness as needed
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Valuer;
